
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Password } from '../models/password.model';
import {BaseService} from "./base.service"

@Injectable()
export class PasswordService extends BaseService {

  constructor(http: HttpClient) {
    super("passwords", http);
  }

  findByEmail(email) {
    let myParams = new HttpParams();
    myParams = myParams.set("email", email)
    return this.http.put(this.baseUrl + "/generate_reset_password_token", myParams)
  }

  save(password: Password) {
    return this.http.post(this.baseUrl , password)
  }

}
