import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Options , LabelType } from 'ng5-slider';
import { ProductService } from '../../services/product.service'
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import templateString from './page-sidebar.component.html';
import styleString from './page-sidebar.component.scss';
import { SubfoldingLinkService } from '../../services/subfolding-link.service';
import { CookiesResetService } from '../../services/cookies-reset.service';

@Component({
  selector: 'app-page-sidebar',
  template: templateString,
  styles: [ styleString ],
  providers:  [ ProductService, SubfoldingLinkService ]
})

export class PageSidebarComponent implements OnInit {

  sideBarFilters = []

  manualRefresh: EventEmitter<void> = new EventEmitter<void>();

  currency;
  sidebarMenu:any;
  @Input() count;
  @Input() taxon_id;
  @Input() vendorId;
  @Input() currentFilters;
  @Input() currentPriceBetween;

  search;
  minValue: number=0;
  maxValue: number=80;
  options: Options = {
    floor: 0,
    ceil: 100,
    step: 5,
    minRange: 1,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return this.currency + value;
        case LabelType.High:
          return this.currency + value;
        default:
          return this.currency + value;
      }
    }
  };
  filterParams = {};
  sideParams = {};

  @Output()
  selectFilters = new EventEmitter();

  constructor(
    private productService: ProductService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    public subfoldingLinkService: SubfoldingLinkService,
    public cookiesResetService: CookiesResetService)
  {
    this.currency = this.cookiesResetService.get('curencySymbol');
    this.currentFilters = this.currentFilters || [{},'']
  }

  ngOnInit() {
    this.getParams();
    this.getFilters();
  }

  getFilters(){
    this.productService.getFilters(this.taxon_id, this.vendorId, this.search).subscribe((data) => {
      this.sidebarMenu = data;
      let priceFilter = this.sidebarMenu.find(obj => obj.name == "product_prices");
      let priceRange = priceFilter["values"];
      this.maxValue = Math.ceil(priceRange[1]["max"]);
      this.options.ceil = this.maxValue;
      this.options.step = (this.minValue - this.maxValue) / 100;
      this.filterParams = {};
      this.sidebarMenu.forEach(filter => {
        if(filter.name != "sub_categories" && filter.name != "product_prices"){
          if (this.currentFilters){
            let applyFilters = this.currentFilters[0];
            if(applyFilters[filter.id]){
              this.filterParams[filter.id] = applyFilters[filter.id];
            }else{
              this.filterParams[filter.id] = []
            }
          } else this.filterParams[filter.id] = []
        }

        if(filter.name == "product_prices" && filter.attribute == "price_between"){
          this.maxValue = filter.values[1]["max"] || this.maxValue;
          this.minValue = filter.values[1]["min"] || this.minValue;
        }
      });

      if(this.currentPriceBetween && this.currentPriceBetween.length < 4){
        this.minValue = this.currentPriceBetween[0] || this.minValue;
        this.maxValue = this.currentPriceBetween[1] || this.maxValue;
      }
    });
  }

  getParams(){
    this.route.params.subscribe(params => {
      if(params['term']){
        this.search = params['term'];
      }
    });
  }

  onSelection(event,id,value){
    if (this.filterParams[id] != "undefined"){
      let values = []
      if (this.filterParams[id]){
        values = this.filterParams[id]
      }
      if (event){
        values.push(value);
      }else{
        values.splice(values.indexOf(value), 1);
      }
      this.filterParams[id] = values
    }
    this.eventEmitter()
  }

  onPriceRangeChange(event){
    this.eventEmitter()
  }

  eventEmitter(){
    let storeCode = "";
    if(this.filterParams && this.filterParams["de"] && this.filterParams["de"].length > 0){
      storeCode = this.cookiesResetService.get('storeCode');
    }
    let searchedhash = {};
    Object.keys(this.filterParams).forEach(key => {
      if(this.filterParams[key].length > 0){
        searchedhash[key] = this.filterParams[key];
      }
    });
    this.sideParams["price_between"]=[this.minValue, this.maxValue];
    this.sideParams["property_filters"]=[searchedhash, storeCode];
    this.cookiesResetService.setItem('search_filters', JSON.stringify(this.sideParams));
    this.selectFilters.emit(this.sideParams);
  }

  refreshSlider(){
    this.manualRefresh.emit();
  }
}


