import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionService } from '../services/session.service'
import {SubfoldingLinkService} from "../services/subfolding-link.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _authService: SessionService,
    private _router: Router,
    public subfoldingLinkService: SubfoldingLinkService) { }

  canActivate(): boolean {
    if (this._authService.loggedIn()) {
      return true
    } else {
      this._router.navigate([this.subfoldingLinkService.linkWithSubdomainOrNot('/signin-signup')])
      return false
    }
  }
}
