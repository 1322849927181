import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductDetailComponent } from '../../product-detail/product-detail.component';
import templateString from './product-info.component.html';
import styleString from './product-info.component.scss';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var $:any;

@Component({
  selector: 'app-product-info',
  template: templateString,
  styles: [ styleString ],
})
export class ProductInfoComponent implements OnInit {
  @Input() product;
  @Output() toggleProductDetail = new EventEmitter<void>();
  infoProduct;
  images;
  safeUrl: SafeResourceUrl;

  constructor(private route: ActivatedRoute, private productDetail: ProductDetailComponent, public sanitizer: DomSanitizer ) { }

  ngOnInit() {
    this.infoProduct = this.product.attributes.info_product;
    if(this.infoProduct.media_type=='embed')
      this.safeUrl= this.sanitizer.bypassSecurityTrustResourceUrl(this.infoProduct.media_url);
    
    
    this.images = this.product.attributes.images.data;
    this.images.sort(function(a,b) {
      return (a.attributes.sort_order_info_product > b.attributes.sort_order_info_product) ? 1 : ((b.attributes.sort_order_info_product > a.attributes.sort_order_info_product) ? -1 : 0);
    });
  }

  sendGiftCard() {
    this.toggleProductDetail.emit();
  }
}
