import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductService } from '../services/product.service'
import { CookieService } from 'ngx-cookie-service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import templateString from './guest-wishlist.component.html';
import styleString from './guest-wishlist.component.scss';
import { SessionService } from '../services/session.service';
import { SubfoldingLinkService } from '../services/subfolding-link.service';
import { CookiesResetService } from '../services/cookies-reset.service';

@Component({
  selector: 'app-guest-wishlist',
  template: templateString,
  styles: [ styleString ],
  providers:  [ ProductService, SessionService, SubfoldingLinkService ]
})

export class GuestWishlistComponent implements OnInit {
  wishedProducts:any;
  currency;
  vendorVisibility;
  isSelectedStore;

  constructor(private window: Window,
    private route: ActivatedRoute,
    private router: Router,
    private spinnerService: Ng4LoadingSpinnerService,
    private productService: ProductService,
    private cookieService: CookieService,
    public sessionService: SessionService,
    public subfoldingLinkService: SubfoldingLinkService,
    public cookiesResetService: CookiesResetService
  ) {
    this.isSelectedStore = this.productService.isSelectedStore();
    this.currency = this.cookiesResetService.get('curencySymbol');
    if(this.cookiesResetService.get("access_token")){
      this.router.navigate([this.subfoldingLinkService.linkWithSubdomainOrNot("/user/wishlist")]);
    }
    if(this.window["data"])
      this.vendorVisibility = this.window["data"]["storeItemVisibility"]["vendorVisibility"]
  }

  ngOnInit() {
      this.getWishedProducts();
  }

  getWishedProducts(){
    if(this.cookiesResetService.getItem('guest_wish_list')){
      this.spinnerService.show();
      var wishedProduts = JSON.parse(this.cookiesResetService.getItem('guest_wish_list'))
      if(wishedProduts.length > 0){
        this.productService.findAllProducts({"id_in": wishedProduts}).subscribe((data) => {
          this.wishedProducts = data["data"];
          this.spinnerService.hide();
        });
      }
    }
    this.wishedProducts = [];
  }

  removeToWishList(id){
    if(this.cookiesResetService.getItem('guest_wish_list')){
      var wishedProduts = JSON.parse(this.cookiesResetService.getItem('guest_wish_list'))
      var index = wishedProduts.indexOf(id);
      wishedProduts.splice(index, 1);
      this.cookiesResetService.setItem('guest_wish_list', JSON.stringify(wishedProduts));
      this.getWishedProducts();
    }
    this.getWishedProducts();
  }

}
