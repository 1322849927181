import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AlertPopupComponent } from '../shared/alert-popup/alert-popup.component';
const alertTextData = require("../../data/alert-texts.json");

export default class CustomAlert {
  public static dialogObject: MatDialog;
  public static otherDialog: MatDialog;
  private currentDialog: any;
  private secondDialog: any;
  private alertData: any;
  private type:string;

  constructor(alertData:any,type='alert') {
    this.currentDialog = CustomAlert.dialogObject;
    this.alertData = alertData;
    this.type = type;
    this.secondDialog = CustomAlert.otherDialog;
  }

  showDialog(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: this.alertData.title,
      message: this.alertData.text,
      noActionCallback: this.alertData.noActionCallback,
      yesActionCallback: this.alertData.yesActionCallback,
      ...this.defaultOptions(this.type),
      showCancel: this.alertData.showCancel || false,
      showNoActionBtn: this.alertData.showNoActionBtn || false,
      showYesActionBtn: this.alertData.showYesActionBtn || false,
      showOk: this.type == 'alert' ? true : (this.alertData.showOk || false),
      yesActionText: this.alertData.yesActionText,
      noActionText: this.alertData.noActionText,
      self: this.alertData.self
    };
    if(this.secondDialog) this.secondDialog.open(AlertPopupComponent, dialogConfig);
    else this.currentDialog.open(AlertPopupComponent, dialogConfig);

    let numbeOfPopUps = document.getElementById('modal-buynow');
    console.log(numbeOfPopUps)
    if(numbeOfPopUps){
    }else{
      let body = document.getElementsByTagName('body')[0];
      body.classList.add("alert-popup-open");
    }
  }

  public static show(key:string, params={},type='alert'):void {
    const customAlert = new CustomAlert({ ...CustomAlert.text(key,params) }, type);
    customAlert.showDialog();
  }

  public static confirm(key:string, params={},options={}):void{
    const customAlert = new CustomAlert({ ...CustomAlert.text(key,params), ...options }, 'confirm');
    customAlert.showDialog();
  }

  public static text(key:string,params=null):any{
    let regExp = /%(\w+)/g;
    let obj = {...alertTextData[key]};
    if(params){
      obj['text'] = obj['text'].replace(regExp, (_,w)=>params[w]);
      return obj;
    }
    return obj;
  }

  defaultOptions(alertType){
    if(alertType=='confirm')
      return {showCancel: false, showOk: false, noActionText: 'NO', yesActionText: 'YES'};
    else
      return {showCancel: false, showOk: true};
  }

}
