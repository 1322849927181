import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '../shared/shared.module';
import { ProductListingRoutingModule } from './product-listing-routing.module';
import {HeaderInterceptor} from "../services/header-interceptor";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
  ],
  imports: [
    ProductListingRoutingModule,
    SharedModule,
    CommonModule
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: ''},
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    CookieService,
    { provide: Window, useValue: window },
    BrowserAnimationsModule,
  ],
  entryComponents: [
  ],
  bootstrap: [
  ]
})
export class ProductListingModule { }
