import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from "@angular/common";
import { TruncateTextPipe } from "./pipes/truncate-text.pipe";
import { AccumulativePricePipe } from "./pipes/accumulative-price.pipe";
import { DecimalPointPipe } from './pipes/decimal-point.pipe';

@NgModule({
  declarations:[ TruncateTextPipe, AccumulativePricePipe, DecimalPointPipe ],
  imports:[ CommonModule ],
  exports:[ TruncateTextPipe, AccumulativePricePipe, DecimalPointPipe ],
  providers:[ DecimalPipe ]
})

export class CustomPipe{}
