import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'decimalPoint' })
export class DecimalPointPipe implements PipeTransform {
    transform(value:any,decmail=2){
        var re = /^[+-]?[0-9]+([.][0-9]+)?([eE][+-]?[0-9]+)?$/;
        if (!re.test(value))
        {
            return value
        }else{
            value = value.toString()
            return value.includes(".")? value.substring(value.indexOf('.') + 1).length == 1 ? value + "0" : value.slice(0, value.indexOf('.') + decmail+1) : value + '.' + "0".repeat(decmail)
        }
    }
}
