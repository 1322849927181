import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import templateString from './return-items-details.component.html';
import styleString from './return-items-details.component.scss';

@Component({
  selector: 'app-return-items-details',
  template: templateString,
  styles: [ styleString ]
})
export class ReturnItemsDetailsComponent implements OnInit {

  returnItemsDetailsForm: FormGroup;
  submitted:boolean;

  constructor(private route: ActivatedRoute, private cookieService: CookieService, private formBuilder: FormBuilder){
    this.returnItemsDetailsForm = this.formBuilder.group({

    });
  }

  ngOnInit() {}

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.returnItemsDetailsForm.invalid) {
      console.log("invalid")
      return;
    }
    else {
      console.log(this.returnItemsDetailsForm.value);
    }
  }

}
