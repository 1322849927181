import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
const environment = require('../../environment.json');

@Injectable()
export class ReviewService {
  protected baseUrl: string="https://api.reviews.co.uk";
  protected http: HttpClient;

  constructor(http: HttpClient, private cookieService: CookieService) {
    this.http = http;
  }

  getProductReviews(sku, perPage, api_key, store_id){
    let options = {
      headers: new HttpHeaders({ 'Content-type': 'application/json', 'apikey': api_key })
    };
    let paramArray= "?store="+ store_id +"&sku="+sku+"&per_page="+perPage;

    return this.http.get(this.baseUrl+ '/product/review'+ paramArray, options)
  }

}
