// api/v2/storefront/users/profile
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {BaseService} from "./base.service"
import { CookieService } from 'ngx-cookie-service';
import { CookiesResetService } from './cookies-reset.service';

@Injectable()
export class UserService extends BaseService {


  protected http: HttpClient;

  options;
  store_id;
  currency;

  constructor(http: HttpClient, private cookieService: CookieService, public cookiesResetService: CookiesResetService) {
    super("users", http);
    this.http = http;

    this.store_id = this.cookiesResetService.get('store');
    this.currency = this.cookiesResetService.get('curency');

    this.options = {
      headers: new HttpHeaders(
          {
            'Content-type': 'application/json',
            'X-Store-Id': this.store_id
          }
      )
    };
  }

  getProfile(){
    const access_token = this.cookiesResetService.get('access_token')
    return this.http.get(this.baseUrl + "/profile?access_token=" + access_token)
  }

  mergeCart(buyNow = false){
    let store_id = this.cookiesResetService.get('store');
    var order_token = this.cookiesResetService.get('order_token');
    const access_token = this.cookiesResetService.get('access_token');

    const options = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        'X-Store-Id': store_id
      })
    };

    const body = {
      "user":{
        "order_token": order_token
      },
      "access_token":  access_token,
      "buy_now_button": buyNow
    };

    return this.http.post(this.baseUrl + "/merge_cart", body, options)
  }

  updateUser(user){
    const access_token = this.cookiesResetService.get('access_token')
    let userParams={
      "user":user
    }
    return this.http.put(this.baseUrl + "/update_user?access_token=" + access_token, userParams)
  }

  getUsersCart(){
    let store_id = this.cookiesResetService.get('store');

    const options = {
      headers: new HttpHeaders(
        {
          'Content-type': 'application/json',
          'X-Store-Id': store_id
        }
      )
    };

    const access_token = this.cookiesResetService.get('access_token')
    return this.http.get(this.baseUrl + "/cart?access_token=" + access_token, options)
  }

  checkGivexBalance(cardNumber, cardPin){
    let params = new HttpParams();
    let headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      'Authorization': 'Basic '+btoa("fooClientIdPassword:secret"),
      'X-Store-Id': this.cookiesResetService.get('store')
    });

    params = params.append('card_number', cardNumber);
    params = params.append('card_pin', cardPin);
    params = params.append('access_token', this.cookiesResetService.get('access_token'));

    const options = { headers: headers };
    return this.http.post(this.baseUrl + "/givex_balance", params.toString(), options)
  }
}
