import { Injectable } from '@angular/core';
import {HttpClient, HttpRequest, HttpErrorResponse,  HttpParams,  HttpHeaders } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { CookiesResetService } from '../services/cookies-reset.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import CustomAlert from '../models/custom-alert.model';
const environment = require('../../environment.json');
@Injectable()
export class TsService{

  hostUrl = environment["tsGiftCardUrl"];
  protected http: HttpClient;

  constructor(
    public cookieResetService: CookiesResetService,
    private spinnerService: Ng4LoadingSpinnerService,
    http: HttpClient
    ){
    this.http = http
  }


  httpOptions = this.requestHeaders(this.cookieResetService.get('tsCustomerEmail'), this.cookieResetService.get('tsCustomerPassword'));

  requestHeaders(email, password){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa( email + ':' + password)
      })
    }
    return httpOptions
  }

  protected handleError(error: HttpErrorResponse) {
    let msg;
    if (error.error['email']!= undefined)
      msg = "email " + error.error['email'][0];
    else if(error.error.errors)
     msg = error.error.errors
    else
     msg = error.error.error
    CustomAlert.show('ERROR',{ error: msg });
    return throwError(msg);
  }

  protected extractData(res) {
    return res;
  }

  customerRegister(params: any)
  {
    return this.http.post(this.hostUrl + "/api/v1/users/create_customer", params)
    .pipe(map(this.extractData),finalize(()=>{
      this.spinnerService.hide();
    }), catchError(this.handleError))
  }

  getUserInfo(){
    return this.http.get(this.hostUrl + "/api/v1/users/profile", this.httpOptions)
    .pipe(map(this.extractData), finalize(()=>{
      this.spinnerService.hide();
    }), catchError(this.handleError))
  }

  customerUpdate(params: any)
  {
    return this.http.put(this.hostUrl + "/api/v1/users/update_customer" , params, this.httpOptions)
    .pipe(map(this.extractData), finalize(()=>{
      this.spinnerService.hide();
    }), catchError(this.handleError))
  }

  customerLogin(params: any){
    return this.http.post(this.hostUrl + "/api/v1/users/signin", params )
    .pipe(map(this.extractData), finalize(()=>{
      this.spinnerService.hide();
    }), catchError(this.handleError))
  }

  changePassword(params: any){
    return this.http.put(this.hostUrl + "/api/v1/users/change_password", params, this.httpOptions)
    .pipe(map(this.extractData), finalize(()=>{
      this.spinnerService.hide();
    }), catchError(this.handleError))
  }

  forgotPassword(params: any){
    return this.http.post(this.hostUrl + "/api/v1/passwords/generate_reset_password_token", params)
    .pipe(map(this.extractData), finalize(()=>{
      this.spinnerService.hide();
    }), catchError(this.handleError))
  }

  forgotPasswordUpdate(params: any){
    return this.http.post(this.hostUrl + "/api/v1/passwords", params)
    .pipe(map(this.extractData), finalize(()=>{
      this.spinnerService.hide();
    }), catchError(this.handleError))
  }

  addCard(params: any){
    return this.http.put(this.hostUrl + '/api/v1/gift_cards/assign_to_customer', params, this.httpOptions)
    .pipe(map(this.extractData), finalize(()=>{
      this.spinnerService.hide();
    }), catchError(this.handleError))
  }

  lostCard(params: any){
     return this.http.put(this.hostUrl + '/api/v1/gift_cards/update_status', params, this.httpOptions)
     .pipe((this.extractData), finalize(()=>{
      this.spinnerService.hide();
    }), catchError(this.handleError))
  }

  removeCard(params: any){
    return this.http.put(this.hostUrl + '/api/v1/gift_cards/remove_card', params, this.httpOptions)
    .pipe((this.extractData), finalize(()=>{
      this.spinnerService.hide();
    }), catchError(this.handleError))
  }

  getCards(){
     return this.http.get(this.hostUrl + '/api/v1/gift_cards',this.httpOptions)
     .pipe(map(this.extractData), finalize(()=>{
      this.spinnerService.hide();
    }), catchError(this.handleError))
  }

  getBalance(params:any){
    return this.http.put(this.hostUrl + "/api/v1/gift_cards/check_balance", params, this.httpOptions)
    .pipe(map(this.extractData), finalize(()=>{
      this.spinnerService.hide();
    }), catchError(this.handleError))
  }

  tsCookiesExist()
  {
    return this.cookieResetService.get('tsCustomerEmail') && this.cookieResetService.get('tsCustomerPassword') ? true : false
  }
}

