import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IframeService {

  constructor() {}

  get(key: string) {
    let data = localStorage.getItem(key) || window['data']['iframe'][key];
    if (data === undefined || data === null) {
      return '';
    }
    else {
      return data;
    }
  }

  set(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  delete(key: string) {
    delete window['data']['iframe'][key];
    localStorage.removeItem(key);
  }
}
