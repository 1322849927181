import { HttpClient, HttpRequest, HttpResponse, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

export abstract class BaseService {

  protected baseUrl: string;
  protected http: HttpClient;
  protected options: HttpRequest<any>;
  protected accessToken: string;

  constructor(
    base: string,
    http: HttpClient,
    options?: HttpRequest<any>
  ) {
    this.baseUrl = "/api/v2/storefront/" + base;
    this.http = http;
    this.options = options;
  }

  findAll(ransackParams: any, appendWithUrl) {
    let myParams = new HttpParams();
    if(JSON.stringify(ransackParams) != JSON.stringify({})){
      myParams = myParams.set("q", JSON.stringify(ransackParams))
    }
    let options = {params: myParams};
    return this.http.get(this.baseUrl+ appendWithUrl, options)
  }

  findOne(id) {
    return this.http.get(this.baseUrl + "/" + id)
  }

}