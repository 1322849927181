import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'removespace'
})
export class RemoveSpacePipe implements PipeTransform {
  transform(label:string, option:string):string {

    label = label.replace(/ /gi, "").toLowerCase();
    option = option.replace(/ /gi, "").toLowerCase();;

    return label+'-'+option;
  }

}