import { Component, OnInit } from '@angular/core';
const clients = require('./clients.component.json');
import templateString from './featured-client.component.html';
import styleString from './featured-client.component.scss';


@Component({
  selector: 'app-featured-client',
  template: templateString,
  styles: [ styleString ]
})
export class FeaturedClientComponent implements OnInit {

  clients:any;

  constructor() { }

  ngOnInit() {
    this.clients = clients;
  }

  imglink(image) {
    return require("../../../images/" + image).default;
  }
}
