import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import templateString from './return-items-labels.component.html';
import styleString from './return-items-labels.component.scss';

@Component({
  selector: 'app-return-items-labels',
  template: templateString,
  styles: [ styleString ]
})
export class ReturnItemsLabelsComponent implements OnInit {

  constructor(private route: ActivatedRoute, private cookieService: CookieService){

  }

  ngOnInit() {}

}
