import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import templateString from './confirm-buy-now-with-cart.component.html';
import styleString from './confirm-buy-now-with-cart.component.scss';
import {SubfoldingLinkService} from "../../services/subfolding-link.service";

@Component({
  selector: 'app-confirm-buy-now-with-cart',
  template: templateString,
  styles: [ styleString ]
})
export class ConfirmBuyNowWithCartComponent implements OnInit {

  constructor(public window: Window, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) data,
              public dialogRef: MatDialogRef<ConfirmBuyNowWithCartComponent>,public subfoldingLinkService: SubfoldingLinkService){

  }

  ngOnInit() {
    let html = document.getElementsByTagName('html')[0];
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('excess-modal-open');
    html.classList.add('scroll-hidden');
  }
  
  onClose(){
    this.dialogRef.close({ continueToBuyNow: false });
    let html = document.getElementsByTagName('html')[0];
    html.classList.remove("scroll-hidden");
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('excess-modal-open');
  }

  sendDataToDetailPage(){
    this.dialogRef.close({ continueToBuyNow: true })
    let html = document.getElementsByTagName('html')[0];
    html.classList.remove("scroll-hidden");
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('excess-modal-open');
  }


}
