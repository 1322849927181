import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { TsGiftCardService } from '../services/ts-gift-card.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import templateString from './ts-gift-card-balance.component.html';
import styleString from './ts-gift-card-balance.component.scss';
import { HttpErrorResponse } from '@angular/common/http';
import CustomAlert from '../../app/models/custom-alert.model';

@Component({
  selector: 'app-ts-gift-card-balance',
  template: templateString,
  styles: [styleString],
  providers: [TsGiftCardService]
})
export class TsGiftCardBalanceComponent implements OnInit {
  balanceForm: FormGroup;
  submitted:boolean;

  constructor(
    private window: Window,
    private formBuilder: FormBuilder,
    private tsGiftCardService: TsGiftCardService,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.balanceForm = this.formBuilder.group({
      giftCardNo: ['', Validators.required],
      pinNo: [''],
      robotCheck: [null, Validators.required]
    });
  }

  get f() { return this.balanceForm.controls; }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  removePinValidation(event) {
    this.balanceForm.controls.pinNo.setErrors(null);
    this.balanceForm.controls.pinNo.clearValidators();
  }

  onSubmit() {
    this.submitted = true;
    if (this.balanceForm.controls.robotCheck?.errors?.required) CustomAlert.show('ERROR',{ error: "CAPTCHA is required" });
    if (this.balanceForm.invalid) return; // stop here if form is invalid
    else{
      this.spinnerService.show();
      let creObj = {}
      creObj = {number: this.balanceForm.value.giftCardNo, pin: this.balanceForm.value.pinNo}
      this.tsGiftCardService.getBalance(creObj).subscribe((data) =>{
        this.spinnerService.hide();
        CustomAlert.show('CURRENT_BALANCE', { balance: data["balance"], status: data["card_status"] });
      },(err:HttpErrorResponse)=>{
        let error = err["_body"];
        if(error){
          error = JSON.parse(error);
          CustomAlert.show('ERROR',{ error: error.error });
        }else{
          error = err.error;
          if(error.message) CustomAlert.show('ERROR',{ error: error.message });
          if(error.errors) {
            if (error.errors[0] == "Your pin is required" || error.errors[0] == "We are unable to find your card pin, please contact your admin") {
              this.balanceForm.controls.pinNo.setValidators([Validators.required]);
              this.balanceForm.controls.pinNo.setErrors({'incorrect': true});
            }
            CustomAlert.show('ERROR',{ error: error.errors[0] });
          }
        }
      });
    }
  }
  onVerify(event: any) {
    this.balanceForm.patchValue({ robotCheck: event });
  }

  onExpired($event: any) {
    this.balanceForm.patchValue({ robotCheck: null });
  }

  onError($event: any) {
    this.balanceForm.patchValue({ robotCheck: null });
  }
}
