import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import templateString from './alert-popup.component.html';
import { SubfoldingLinkService } from '../../services/subfolding-link.service';
import styleString from './alert-popup.component.scss';

@Component({
  selector: 'app-alert-popup',
  template: templateString,
  styles: [ styleString ],
})
export class AlertPopupComponent implements OnInit {

  showCancel:boolean = false;
  showOk:boolean = false;
  showNoActionBtn:boolean = false;
  showYesActionBtn:boolean = false;
  noActionText:string;
  yesActionText:string;
  noActionCallback:any = null;
  yesActionCallback:any = null;
  self:any = null
  constructor(private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private subfoldingLinkService: SubfoldingLinkService,
    public dialogRef: MatDialogRef<AlertPopupComponent>
  ) {
    this.showCancel = data.showCancel || false
    this.showOk = data.showOk || false
    this.showNoActionBtn = data.showNoActionBtn || false
    this.showYesActionBtn = data.showYesActionBtn || false
    this.noActionText = data.noActionText || 'No'
    this.yesActionText = data.yesActionText || 'Yes'
    this.noActionCallback = data.noActionCallback || null
    this.yesActionCallback = data.yesActionCallback || null
    this.self = data.self || null
  }

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
    this.removeScroll();
  }

  onNo(){
    if (this.showNoActionBtn && this.noActionCallback && typeof this.noActionCallback == 'function')
      (this.self ? this.noActionCallback(this.self) : this.noActionCallback());

    this.dialogRef.close();
    this.removeScroll();
  }

  onYes(){
    if (this.showYesActionBtn && this.yesActionCallback && typeof this.yesActionCallback == 'function'){
      this.self ? this.yesActionCallback(this.self) : this.yesActionCallback()
      this.dialogRef.close();
      this.removeScroll();
    }
  }

  removeScroll(){
    let numbeOfPopUps = document.getElementById('modal-buynow');
    if(numbeOfPopUps){
    }else{
      let html = document.getElementsByTagName('html')[0];
      html.classList.remove("scroll-hidden");
    }
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove("alert-popup-open");
  }

}
