import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { BaseService } from "./base.service"
import { CookieService } from 'ngx-cookie-service';
import { CookiesResetService } from '../services/cookies-reset.service';

@Injectable()
export class CountryService extends BaseService {

  constructor(http: HttpClient, private cookieService: CookieService, public cookiesResetService: CookiesResetService) {
    super("countries", http);
  }

  removeCountriesWithStoreName(allCountries = false) {
    let options = {
      headers: new HttpHeaders(
          {
            'X-Store-Id': this.cookiesResetService.get('store')
          }
      )
    };
    return this.http.get(this.baseUrl + "/remove_countries_with_store_name" + `?all_countries=${allCountries}`, options)
  }

}
