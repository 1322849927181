import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CookiesResetService } from './cookies-reset.service';

@Injectable()
export class SubfoldingLinkService{
  storeCode;
  constructor(private cookieService: CookieService, private window: Window,
              public cookiesResetService: CookiesResetService) {
    this.storeCode = this.cookiesResetService.get('storeCode')
  }

  public linkWithSubdomainOrNot(link){
    if(this.window["data"]["allowSubFolderUrls"] == 1 || this.window["data"]["allowSubFolderUrls"] == 2){
      return "/" + this.storeCode + link
    }else{
      return link
    }
  }

}
