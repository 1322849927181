import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TsService } from '../services/ts.service';
import { SubfoldingLinkService } from '../services/subfolding-link.service';

@Injectable()
export class TsGuard implements CanActivate {
  constructor(
    private _router: Router,
    public tsService: TsService,
    public subfoldingLinkService: SubfoldingLinkService
	) { }

  canActivate(): boolean {
    let url =  window.location.pathname;
    if(!url.includes('ts-login') && !url.includes('ts-register') && !url.includes('ts-forgot-password') && !(url == '/'))
    {
      if(!this.tsService.tsCookiesExist()){
        this._router.navigate([this.subfoldingLinkService.linkWithSubdomainOrNot('/ts-portal/ts-login')])
        return false
      }
    }
    else if (!(url == '/')){
      if(this.tsService.tsCookiesExist()){
        this._router.navigate([this.subfoldingLinkService.linkWithSubdomainOrNot('/ts-portal/ts-home')])
        return false
      }
    }
    return true
  }
}
