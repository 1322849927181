import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { IframeService } from './iframe.service';

@Injectable()
export class CookiesResetService {

  isIframeStore: boolean = false;

  constructor(private cookieService: CookieService, private iframeService: IframeService ) {
    if (window['data']['store_type'] == 'iframe') {
      this.isIframeStore = true;
    }
  }

  set(name, value, expireDate = null){
    let newNameWithCodeId = window['data']['storeId'] + '_' + name;
    if (this.isIframeStore) {
      this.iframeService.set(newNameWithCodeId, value)
    } else {
      this.cookieService.set(newNameWithCodeId, value, expireDate, "/")
    }
  }

  get(name){
    let newNameWithCodeId = window['data']['storeId'] + '_' + name;
    if (this.isIframeStore) {
      let value = this.iframeService.get(newNameWithCodeId)
      return value
    } else {
      if(this.cookieService.get(newNameWithCodeId)){
        return this.cookieService.get(newNameWithCodeId);
      }else{
        let storedItem =this.cookieService.get(name);
        if(storedItem){
          this.cookieService.set(newNameWithCodeId, storedItem, null, "/")
          this.cookieService.delete(name, "/");
        }
        return storedItem;
      }
    }
  }

  delete(name, path){
    let newNameWithCodeId = window['data']['storeId'] + '_' + name;
    if (this.isIframeStore) {
      this.iframeService.delete(newNameWithCodeId)
    } else {
      if(this.cookieService.get(newNameWithCodeId)){
        this.cookieService.delete(newNameWithCodeId, path);
      }else{
        this.cookieService.delete(name, path);
      }
    }
  }

  check(name){
    let newNameWithCodeId = window['data']['storeId'] + '_' + name;
    if(this.cookieService.get(newNameWithCodeId) || this.cookieService.get(name)){
      return true;
    }else{
      return false;
    }
  }

  setItem(name, value){
    let newNameWithCodeId = window['data']['storeId'] + '_' + name;
    localStorage.setItem(newNameWithCodeId, value)
  }

  getItem(name){
    let newNameWithCodeId = window['data']['storeId'] + '_' + name;
    if(localStorage.getItem(newNameWithCodeId)){
      return localStorage.getItem(newNameWithCodeId);
    }else{
      let storedItem =localStorage.getItem(name);
      if(storedItem){
        localStorage.setItem(newNameWithCodeId, storedItem);
        localStorage.removeItem(name);
      }
      return storedItem;
    }
  }

  removeItem(name){
    let newNameWithCodeId = window['data']['storeId'] + '_' + name;
    if(localStorage.getItem(newNameWithCodeId)){
      localStorage.removeItem(newNameWithCodeId);
    }else{
      localStorage.removeItem(name);
    }
  }

  setSessionItem(name, value){
    let newNameWithCodeId = window['data']['storeId'] + '_' + name;
    sessionStorage.setItem(newNameWithCodeId, value)
  }

  getSessionItem(name){
    let newNameWithCodeId = window['data']['storeId'] + '_' + name;
    if(sessionStorage.getItem(newNameWithCodeId)){
      return sessionStorage.getItem(newNameWithCodeId);
    }else{
      let storedItem =sessionStorage.getItem(name);
      if(storedItem){
        sessionStorage.setItem(newNameWithCodeId, storedItem);
        sessionStorage.removeItem(name);
      }
      return storedItem;
    }
  }

  removeSessionItem(name){
    let newNameWithCodeId = window['data']['storeId'] + '_' + name;
    if(sessionStorage.getItem(newNameWithCodeId)){
      sessionStorage.removeItem(newNameWithCodeId);
    }else{
      sessionStorage.removeItem(name);
    }
  }

}
