import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from "./base.service"
import { CookiesResetService } from '../services/cookies-reset.service';

@Injectable()
export class GivexService extends BaseService {

  constructor(http: HttpClient, public cookiesResetService: CookiesResetService
    ) {
    super("givex_cards", http);
  }

  givexRequest(methodparams:Array<string>,method)
  {
    var requestParams= {
      "jsonrpc":"2.0",
      "method":method,
      "params" :methodparams, 
      "id": 100
    };
    return this.http.post(this.baseUrl + '/givex_request' , requestParams)
  }
  
  givexCookiesExist()
  {
    return this.cookiesResetService.get('customerLogin') && this.cookiesResetService.get('customerPassword') ? true : false
  }
  getCredentials(){
  const customerLogin =  this.cookiesResetService.get('customerLogin')
  const customerPassword =  this.cookiesResetService.get('customerPassword')
  return {customerLogin: customerLogin, customerPassword: customerPassword}
}

}

