import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductListingComponent } from './product-listing.component';

const routes: Routes = [
    {   path: '', component: ProductListingComponent  },
    {   path: ':category/:id/:sub', component: ProductListingComponent   },
    {   path: ':category/:id/:sub/:sub1', component: ProductListingComponent   },
    {   path: ':category/:id/:sub/:sub1/:sub2', component: ProductListingComponent   },
    {   path: ':category/:id/:sub/:sub1/:sub2/:sub3', component: ProductListingComponent   },
    {   path: ':category/:id/:sub/:sub1/:sub2/:sub3/:sub4', component: ProductListingComponent   },
    {   path: ':category/:id/:sub/:sub1/:sub2/:sub3/:sub4/:sub5', component: ProductListingComponent   },
    {   path: ':category/:id', component: ProductListingComponent   },
    {   path: 'vendors/:vendor_id/products', component: ProductListingComponent   }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
    entryComponents: [
        ProductListingComponent
    ]
})

export class ProductListingRoutingModule { }