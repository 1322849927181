import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { GivexService } from '../services/givex.service';
import { SubfoldingLinkService } from '../services/subfolding-link.service';

@Injectable()
export class GivexGuard implements CanActivate {
  constructor(
    private _router: Router,
    public givexService: GivexService,
    public subfoldingLinkService: SubfoldingLinkService
	) { }

  canActivate(): boolean {
    if(!window.location.pathname.includes('givex-login') && !window.location.pathname.includes('givex-register') && !(window.location.pathname == '/'))
    {
      if(!this.givexService.givexCookiesExist())
      {
        this._router.navigate([this.subfoldingLinkService.linkWithSubdomainOrNot('/givex-portal/givex-login')])
        return false
      }
    }
    else if (!(window.location.pathname == '/')){
      if(this.givexService.givexCookiesExist())
      {
        this._router.navigate([this.subfoldingLinkService.linkWithSubdomainOrNot('/givex-portal/givex-home')])
        return false
      }
    }
   return true
  }
}
