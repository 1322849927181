import {Component, Input} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import templateString from './buy-now-login-cart.component.html';
import styleString from './buy-now-login-cart.component.scss';
import { LoginCartComponent } from '../../login-cart/login-cart.component'
import {SessionService} from "../../../../services/session.service";
import {UserService} from "../../../../services/user.service";
import {WishedProductService} from "../../../../services/wished-product.service";
import {SubfoldingLinkService} from "../../../../services/subfolding-link.service";
import {FormBuilder} from "@angular/forms";
import {CookieService} from "ngx-cookie-service";
import {Router} from "@angular/router";
import { MatDialog } from '@angular/material/dialog';
import {CookiesResetService} from "../../../../services/cookies-reset.service";
import CustomAlert from '../../../../models/custom-alert.model';

@Component({
  selector: 'app-buy-now-login-cart',
  template: templateString,
  styles: [ styleString ],
  providers:  [ SessionService, UserService, WishedProductService, SubfoldingLinkService ]
})
export class BuyNowLoginCartComponent extends LoginCartComponent {

  @Input() allDigital:boolean;

  constructor(public window: Window,
              public sessionService: SessionService,
              public userService: UserService,
              public wishedProductService: WishedProductService,
              public formBuilder: FormBuilder,
              public cookieService: CookieService,
              public router: Router,
              public subfoldingLinkService: SubfoldingLinkService,
              public cookiesResetService: CookiesResetService,
              private dialog: MatDialog
  ) {
    super(window,sessionService, userService,wishedProductService, formBuilder, cookieService, router, subfoldingLinkService, cookiesResetService);
    CustomAlert.otherDialog = dialog;
  }

  onSubmit() {
    var email = (this.login.get('email').value).toLowerCase();
    var password = this.login.get('password').value;
    this.submitted = true;
    // stop here if form is invalid
    if (this.login.invalid) {
      return;
    } else {
      this.sessionService.obtainAccessToken({username: email, password: password}).subscribe((data: any) => {
        var tokenData = data;
        if(tokenData.roles == "customer"){
          CustomAlert.confirm('LOGIN',{},{
            showYesActionBtn:true,
            yesActionText: 'OK',
            yesActionCallback: (self) => {
              self.sessionService.deleteGuestEmail();
              self.sessionService.saveToken(tokenData);
              self.userService.mergeCart(true).subscribe((token: any) => {
                var orderToken = token["token"];
                // self.cookiesResetService.setItem('order_token', JSON.stringify(orderToken));
                self.cookiesResetService.set("order_token", orderToken, null);
                if(self.allDigital) window.location.href = self.subfoldingLinkService.linkWithSubdomainOrNot('/checkout');
                else window.location.href = self.subfoldingLinkService.linkWithSubdomainOrNot('/cart');
              }, (err:HttpErrorResponse) => {
                let error = null;
                if(err["_body"]) error = JSON.parse(err["_body"]);
                else error = err.error;
                CustomAlert.show('ERROR',{ error: error.error });
              });
            },
            self: this
          });
        }else{
          CustomAlert.show('CUSTOMER_IS_NOT_AUTHORIZED', {}, 'alert');
        }
      }, err => { CustomAlert.show('INCORRECT_USERNAME_OR_PASSWORD'); });
    }
  }
}
