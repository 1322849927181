import { Component, OnInit, Input} from '@angular/core';
import { CountryService } from '../../services/country.service';
import { CookieService } from 'ngx-cookie-service';
import { Validators, FormGroup, ValidatorFn } from '@angular/forms';
import templateString from './address.component.html';
import styleString from './address.component.scss';
import { CookiesResetService } from '../../services/cookies-reset.service';
import { AddressFormFormat } from '../../models/address_form_format.model';

@Component({
  selector: 'app-address',
  template: templateString,
  styles: [ styleString ],
  providers: [CountryService]
})
export class AddressComponent implements OnInit {

  @Input() address:FormGroup;
  @Input() submitted;
  @Input() cartTotal;
  @Input() showAllContries = false;
  @Input() productType;
  @Input() showSlots;
  @Input() settledCountryState;

  addressFormFormater: AddressFormFormat;
  countries = [];
  addressFormat;
  selectedCountryStates = null;

  constructor(
    public cookieService: CookieService,
    public countryService: CountryService,
    public cookiesResetService: CookiesResetService,
    public window: Window
  ){}

  ngOnInit() {
    this.addressFormFormater = new AddressFormFormat();
    this.addressFormat = this.addressFormFormater.getDefaultAddressFormats();
    if(this.address.value.country) this.setAddressFormFormat(this.address.value.country);
    this.fetchAllCountries();
    this.addValidatorToCommonFields();
    this.subscribeAddressFormchange();
  }

  get f() { return this.address.controls; }

  subscribeAddressFormchange(){
    this.address.controls.country.valueChanges.subscribe(selectedValue  => {
      if(this.address.value.country && this.address.value.country != selectedValue) this.clearForm();
      this.setAddressFormFormat(selectedValue);
    })
  }

  clearForm() {
    for(let key in this.address.controls){
      if(key != 'country' && this.addressFormFormater.addressFields.includes(key)){
        let field = this.address.controls[key];
        field.clearValidators();
        if(key == 'state_id'){
          field.setValue('',{emitEvent: false});
        }else{
          field.setValue(null,{emitEvent: false});
        }
        field.updateValueAndValidity({emitEvent: false});
      }
    }
  }

  setAddressFormFormat(iso){
    this.addressFormat = this.addressFormFormater.getAddressFormats(iso);
    this.addValidatorToCountryFields(iso);
    this.addValidatorToCommonFields();
  }

  capitalizeFieldText(key){
    let field = this.address.controls[key];
    field.setValue(field.value.toUpperCase());
  }

  fetchAllCountries(){
    this.countryService.removeCountriesWithStoreName(this.showAllContries).subscribe(
      (countries) => {
        this.countries = countries["data"].sort((a,b) => (a.attributes.name > b.attributes.name) ? 1 : ((b.attributes.name > a.attributes.name) ? -1 : 0));
        this.setSelectedCountry({target: {value: this.address.value.country}})
      });
  }

  removeValidators(key) {
    let field = this.address.controls[key];
    field.clearValidators();
    field.updateValueAndValidity({emitEvent: false});
  }
  
  addValidators(key) {
    let fieldFormat = this.addressFormat.fields[key];
    let field = this.address.controls[key];

    const keyValidations: Array<ValidatorFn> = [];
    if(fieldFormat.required) keyValidations.push(Validators.required);
    if(fieldFormat.validation_regix) keyValidations.push(Validators.pattern(fieldFormat.validation_regix));
    if(window['data']['preferences']['state_based_tax']){
      if(key !== 'state') field.setValidators(keyValidations);
      else this.addressFormat.fields['state']['visible'] = false;
    } else{
      if(key !== 'state_id') field.setValidators(keyValidations);
      else this.addressFormat.fields['state_id']['visible'] = false;
    }
      
    field.updateValueAndValidity({emitEvent: false});
  }

  addValidatorToCountryFields(iso){
    for (const key in this.addressFormat.fields) {
      if(!this.addressFormFormater.commonValidations.includes(key)) this.addValidators(key);
    }
    if (this.countries) this.setSelectedCountry({target:{value: iso}})
  }

  addValidatorToCommonFields(){
    var form = this.address
    for (const key of this.addressFormFormater.commonValidations) {
      form.controls[key].setValidators([Validators.required]);
      form.controls[key].updateValueAndValidity({emitEvent: false});
    }
  }

  setSelectedCountry(event){
    const selectedCountry = this.countries.filter(country => country.attributes.iso == event.target.value)[0];
    if (selectedCountry) this.selectedCountryStates = [].concat(selectedCountry.attributes.country_state)
  }
}