import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const storeId = window['data']['storeId']
        const modifiedReq = req.clone({
            headers: req.headers.set('X-Store-Id', storeId),
        });
        return next.handle(modifiedReq);
    }
}