
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from "./base.service";
import { CookieService } from 'ngx-cookie-service';
import { CookiesResetService } from './cookies-reset.service';

@Injectable()
export class VendorService extends BaseService {
  access_token;

  protected http: HttpClient;

  constructor(http: HttpClient, private cookieService: CookieService,
              public cookiesResetService: CookiesResetService) {
    super("vendors", http);
    this.http = http;
    this.access_token = this.cookiesResetService.get('access_token')
  }

  show(id){
    let additional_params = ''
    if(this.access_token){
      additional_params = "?access_token=" + this.access_token + "&find_from_all=" + "yes"
    }else{
      additional_params = "?find_from_all=" + "yes"
    }

    return this.http.get(this.baseUrl + "/" + id+ additional_params  )
  }

  get_vendor(id){
    return this.http.get(this.baseUrl + "/" + id+ "/get_vendor")
  }
}
