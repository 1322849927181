import { Component, OnInit } from '@angular/core';
import { FormGroup  } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import templateString from './login-signup.component.html';
import styleString from './login-signup.component.scss';
import { CookiesResetService } from '../../services/cookies-reset.service';
import {SubfoldingLinkService} from "../../services/subfolding-link.service";

@Component({
  selector: 'app-login-signup',
  template: templateString,
  styles: [ styleString ],
})
export class LoginSignupComponent implements OnInit {

  login: FormGroup;
  submitted:boolean;
  constructor(
    public cookieService: CookieService,
    public router: Router,
    public cookiesResetService: CookiesResetService,
    public subfoldingLinkService: SubfoldingLinkService
  ){
    if(this.cookiesResetService.get("access_token")){
      this.router.navigate([this.subfoldingLinkService.linkWithSubdomainOrNot("/user/account")]);
    }
  }

  ngOnInit() {
  }

  onSubmit() {

  }

}
