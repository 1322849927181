import { Component, OnInit, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ProductService } from '../../services/product.service';
import { WishlistService } from '../../services/wishlist.service';
import { WishedProductService } from '../../services/wished-product.service';
import { WishedProduct } from '../../models/wished-product.model';
import templateString from './related-products.component.html';
import styleString from './related-products.component.scss';
import { SessionService } from '../../services/session.service'
import {SubfoldingLinkService} from "../../services/subfolding-link.service";
import { CookiesResetService } from '../../services/cookies-reset.service';

@Component({
  selector: 'app-related-products',
  template: templateString,
  styles: [ styleString ],
  providers:  [ WishlistService, WishedProductService, SessionService, ProductService, SubfoldingLinkService]
})
export class RelatedProductsComponent implements OnInit {

  @Input() title;
  @Input() vendor;
  @Input() dataSource;
  @Input() vendorLandingPage;
  @Input() vendorMicroSite;
  @Input() vendorSlug;
  @Input() spanClass;

  toggle = {};
  currency;
  vendorVisibility;
  isSelectedStore;

  constructor(
    private productService: ProductService,
    private cookieService: CookieService,
    private wishlistService: WishlistService,
    private wishedProductService: WishedProductService,
    private window: Window,
    public sessionService: SessionService,
    public subfoldingLinkService: SubfoldingLinkService,
    public cookiesResetService: CookiesResetService
  ) {
    this.isSelectedStore = this.productService.isSelectedStore();
    this.currency = this.cookiesResetService.get('curencySymbol');
    if(this.window["data"])
      this.vendorVisibility = this.window["data"]["storeItemVisibility"]["vendorVisibility"]
  }

  ngOnInit() {
    if(this.dataSource){
      this.setWishedProduct(this.dataSource);
    }
  }

  setWishedProduct(products){
    if(this.cookiesResetService.get("access_token")){
      this.wishlistService.findUserList().subscribe(
        (wishlist) => {
          let wishedItems = wishlist["data"].attributes.wished_products.data
          wishedItems.forEach( wishedProdut => {
            products.forEach( product => {
              if(wishedProdut.attributes.prodcut_id == product.id){
                product.wished = true
                product.wishedProductId = wishedProdut.id
              }
            })
          });
        });
    }else{
      var wishedProduts = JSON.parse(this.cookiesResetService.getItem('guest_wish_list'))
      products.forEach( product => {
        if(wishedProduts && wishedProduts.includes(product.id)){
          product.wished = true
        }else{
          product.wished = false
        }
      })
    }
  }

  addProductToWishList(product, status){
    console.log(product)
    if(this.cookiesResetService.get("access_token")){
      if(status == "remove"){
        this.wishedProductService.delete(product.wishedProductId).subscribe( obj => {
          product.wished = false
          product.wishedProductId = null
        });
      }else{
        let wishedProdutData = new WishedProduct(product.id);
        this.wishedProductService.save(wishedProdutData).subscribe((data: any)  =>{
          product.wished = true
          product.wishedProductId = data.wished_product_id
        });
      }
    }else{
      if(this.cookiesResetService.getItem('guest_wish_list')){
        var wishedProduts = JSON.parse(this.cookiesResetService.getItem('guest_wish_list'))
        if(status == "remove"){
          product.wished = false
          var index = wishedProduts.indexOf(product.id);
          wishedProduts.splice(index, 1);
        }else{
          product.wished = true
          wishedProduts.push(product.id);
        }
        this.cookiesResetService.setItem('guest_wish_list', JSON.stringify(wishedProduts));
      }else{
        product.wished = true
        this.cookiesResetService.setItem('guest_wish_list', JSON.stringify([product.id]));
      }
    }
    // if(status != "remove"){
    //   if(this.window["gtag"]){
    //     this.window["gtag"]('event', 'add_to_wishlist', {
    //       currency: this.currency,
    //       items: [{
    //         id: product.attributes.slug,
    //         name: product.attributes.name,
    //         brand: product.attributes.vendor_name,
    //         price: product.attributes.price
    //       }],
    //       value: product.attributes.price
    //     });
    //   }
    // }
  }

}
