import { Injectable } from '@angular/core';
import { BaseService } from "./base.service";
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HtmlComponentService extends BaseService{
  protected http: HttpClient;

  constructor(private window: Window, private cookieService:CookieService, http: HttpClient) {
    super("", http);
    this.http = http;
  }

     getNavBarData(){
      return this.http.get(this.baseUrl + "/navbar_data")
     }

     getFooterData(){
      return this.http.get(this.baseUrl + "/footer_data")
     }

    getNewLetterData(){
        return this.http.get(this.baseUrl + "/newletter_data")
    }
    
}
