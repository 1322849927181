import { Component, OnInit, Inject } from '@angular/core';
import { BlogsService } from '../services/blogs.service'
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import templateString from './blog.component.html';
import styleString from './blog.component.scss';

@Component({
  selector: 'app-blog',
  template: templateString,
  styles: [ styleString ],
  providers:  [ BlogsService ]
})
export class BlogComponent implements OnInit {

  Arr = Array; //Array type captured in a variable
  num:number = 12;
  blogs = [];
  totalCount;
  p: number = 1;
  perPage: number = 24;

  constructor(private window: Window, private blogsService: BlogsService,
              private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.getblogs();
  }

  getblogs(){
    this.spinnerService.show();
    let skipBlogs = this.perPage * (this.p - 1)
    this.blogsService.getBlogs(this.perPage, skipBlogs).subscribe((data) => {
      this.blogs = data["data"]["contentView_content_9d9581daf2cf4b49b4ae83e951b380ff"]["edges"];
      this.totalCount = data["data"]["contentView_content_9d9581daf2cf4b49b4ae83e951b380ffCount"]["aggregate"]["count"];
      this.spinnerService.hide();
    });
  }

  pageChanged(event){
    this.blogs = []
    if (event != this.p){
      this.p = event
      this.window.scrollTo(0, 0)
      this.getblogs();
    }
  }

  setPerPage(event){
    this.perPage = +event.target.value
    this.getblogs();
  }

}
