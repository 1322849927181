import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Injectable()
export class BlogsService {

  protected baseUrl: string="https://api-euwest.graphcms.com/v1/cjypvwyf5237n01djbcp97btj/master";
  protected http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  getBlogs(perPage, skipBlogs){
    let blogsParams={
      "operationName": "contentView_9d9581daf2cf4b49b4ae83e951b380ff",
      "query": "query contentView_9d9581daf2cf4b49b4ae83e951b380ff($first: Int, $skip: Int, $where: BlogPostWhereInput, $orderBy: BlogPostOrderByInput) { contentView_content_9d9581daf2cf4b49b4ae83e951b380ff: blogPostsConnection(first: $first, skip: $skip, where: $where, orderBy: $orderBy) {   edges {     node {       status       updatedAt       createdAt       id       title       urlKey       dateAndTime       tags       blogAuthor {         id       }       featuredImage {         status         updatedAt         createdAt         id         handle         fileName         height         width         size         mimeType       }       content {         raw         html       }     }   } } contentView_content_9d9581daf2cf4b49b4ae83e951b380ffCount: blogPostsConnection(where: $where) {   aggregate {     count   } }}",
      "variables": {first: perPage, skip: skipBlogs, orderBy: 'dateAndTime_DESC', where: {AND: []}}
    }

    return this.http.post(this.baseUrl, blogsParams)
  }

  getBlog(url){
    let blogsParams={
      "query": "query ($urlKey: String) {  blogPost(where: {urlKey: $urlKey}) {    status    updatedAt    createdAt    id    title    urlKey    dateAndTime    tags    blogAuthor {      id    }    featuredImage {      status      updatedAt      createdAt      id      handle      fileName      height      width      size      mimeType    }    content {      raw      html    }  }}",
      variables: {urlKey: url}
    }

    return this.http.post(this.baseUrl, blogsParams)
  }

}
