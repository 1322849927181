import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import templateString from './return-item-single-label.component.html';
import styleString from './return-item-single-label.component.scss';

@Component({
  selector: 'app-return-item-single-label',
  template: templateString,
  styles: [ styleString ]
})
export class ReturnItemSingleLabelComponent implements OnInit {

  constructor(private route: ActivatedRoute, private cookieService: CookieService){

  }

  ngOnInit() {}

}
