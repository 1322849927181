import {Component, Input} from '@angular/core';
import templateString from './buy-now-guest-checkout.component.html';
import styleString from './buy-now-guest-checkout.component.scss';
import { GuestCheckoutComponent } from '../../guest-checkout/guest-checkout.component'
import {SessionService} from "../../../../services/session.service";
import {SubscriptionService} from "../../../../services/subscription.service";
import {SubfoldingLinkService} from "../../../../services/subfolding-link.service";
import {FormBuilder} from "@angular/forms";

@Component({
  selector: 'app-buy-now-guest-checkout',
  template: templateString,
  styles: [ styleString ],
  providers:  [ SessionService, SubscriptionService, SubfoldingLinkService ]
})
export class BuyNowGuestCheckoutComponent extends GuestCheckoutComponent {

  @Input() allDigital:boolean;

  constructor(public window: Window,
              public sessionService: SessionService,
              public formBuilder: FormBuilder,
              public subscriptionService: SubscriptionService,
              public subfoldingLinkService: SubfoldingLinkService
  ){
    super(window, sessionService, formBuilder, subscriptionService, subfoldingLinkService )
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.guestCheckout.invalid) return;
    else {
      let email = (this.guestCheckout.get('email').value).toLowerCase();
      let email_subscribed = this.guestCheckout.get('news_letter').value;

      this.sessionService.saveGuestEmail(email);

      if(email_subscribed){
        this.subscriptionService.subscribeMailchimp(email, false, email_subscribed).subscribe((subscription) => {
              subscription = subscription['body'];
              console.log(subscription['message']);
            }, (err) => { console.log("error: " + err['statusText']); },
            () => { window.location.href = this.subfoldingLinkService.linkWithSubdomainOrNot('/checkout'); } );
      } else{
        if(this.allDigital){
          window.location.href = this.subfoldingLinkService.linkWithSubdomainOrNot('/checkout');
        }else{
          window.location.href = this.subfoldingLinkService.linkWithSubdomainOrNot('/cart');
        }
      }
    }
  }

}
