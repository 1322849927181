export class User {
  private email: string;
  private password: string;
  private news_letter: boolean;
  private enabled_marketing: boolean;

  constructor(email: string, password: string, subscribed: boolean, enabledMarketing?: boolean) {
    this.email = email;
    this.password = password;
    this.news_letter = subscribed;
    this.enabled_marketing = enabledMarketing;
  }

}
