import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionService } from '../services/session.service'
import { CookieService } from 'ngx-cookie-service';
import { CookiesResetService } from '../services/cookies-reset.service';
import {SubfoldingLinkService} from "../services/subfolding-link.service";

@Injectable()
export class CustomerGuard implements CanActivate {
  constructor(private _authService: SessionService,
    private _router: Router,private cookieService: CookieService, public cookiesResetService: CookiesResetService, public subfoldingLinkService: SubfoldingLinkService) { }

  canActivate(): boolean {
    const value: string = this.cookiesResetService.get('roles');
    if (value == "customer"){
      return true;
    }else{
      return false;
      this._router.navigate([this.subfoldingLinkService.linkWithSubdomainOrNot('/signin-signup')])
    }
  }
}
