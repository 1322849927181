import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from "./base.service"
import { CookieService } from 'ngx-cookie-service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Address } from '../models/address.model';
import { CookiesResetService } from './cookies-reset.service';

@Injectable()
export class FollowService extends BaseService {
  protected http: HttpClient;
  access_token;
  constructor(http: HttpClient, private cookieService: CookieService, public cookiesResetService: CookiesResetService) {
    super("follows", http);
    this.http = http;
    this.access_token = this.cookiesResetService.get('access_token')
  }

  delete(id) {
    return this.http.delete(this.baseUrl + '/' + id + "?access_token=" + this.access_token)
	}

  findOne(id) {
    return this.http.get(this.baseUrl + '/' + id + "?access_token=" + this.access_token)
	}

  update(id, address: Address) {
    let addressParams={
      "follow":address
    }
    return this.http.put(this.baseUrl + '/' + id + "?access_token=" + this.access_token, addressParams)
	}

  save(address: Address) {
    let addressParams={
      "follow":address
    }
    return this.http.post(this.baseUrl + "?access_token=" + this.access_token, addressParams)
	}

}
