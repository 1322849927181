export class Password {

  private reset_password_token: string;
  private password: string;
  private password_confirmation: string;

  constructor(reset_password_token: string, password: string, password_confirmation: string) {
    this.reset_password_token = reset_password_token;
    this.password = password;
    this.password_confirmation = password_confirmation;
  }

}
