import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import templateString from './buy-now-login.component.html';
import styleString from './buy-now-login.component.scss';

@Component({
  selector: 'app-buy-now-login',
  template: templateString,
  styles: [ styleString ]
})
export class BuyNowLoginComponent implements OnInit {

  guestEnabled:boolean = false;
  data;

  constructor(public window: Window, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) data,public dialogRef: MatDialogRef<BuyNowLoginComponent>){
    this.data = data['allDigital'];
  }

  ngOnInit() {
    let html = document.getElementsByTagName('html')[0];
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('login-modal-open');
    html.classList.add('scroll-hidden');
  }
  
  onClose(){
    this.dialogRef.close();
    let html = document.getElementsByTagName('html')[0];
    html.classList.remove("scroll-hidden");
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-modal-open');
  }

  enableGuest() {
    this.guestEnabled = true;
  }

}
