import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { BaseService } from "./base.service"
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class PagesService extends BaseService {

  constructor(http: HttpClient, private cookieService: CookieService) {
    super("pages", http);
  }

  getByUrl(id) {
    return this.http.get(this.baseUrl + "/get_by_url" + "?id=" + id)
  }

}
