import { Component, OnInit, Input } from '@angular/core';
import templateString from './home-carousel.component.html';
import styleString from './home-carousel.component.scss';
import next from '../../../images/next-arrow.png';
import back from '../../../images/back-arrow.png';

declare var $:any;


@Component({
  selector: 'app-home-carousel',
  template: templateString,
  styles: [ styleString ]
})
export class HomeCarouselComponent implements OnInit {


  @Input() listing:any;

  caroselSpacing;

  constructor(private window: Window) {
  }

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(function () {
      if(this.window["data"])
        this.caroselSpacing = this.window["data"]["caroselSpacing"]
      $('.home-carousel').owlCarousel({
        loop: false,
        autoplay:false,
        autoplayTimeout:3000,
        nav: true,
        autoplayHoverPause:true,
        stagePadding: 0,
        dots: false,
        mouseDrag: true,
        responsiveClass:true,
        responsive: {
          0:{
            items: 2,
            stagePadding: 0,
            nav: true,
            dots: false
          },
          450:{
            items: 2,
            stagePadding: 0,
            nav: true,
            dots: false
          },
          768:{
            items: 2,
            margin: 27,
            dots: false,
            loop:true,
            stagePadding: 50
          },
          1000: {
            items: 4,
            margin: this.caroselSpacing,
            dots: false
          },
          1300:{
            items: 4,
            margin: this.caroselSpacing,
            dots: false
          },
          1600:{
            items: 4,
            margin: this.caroselSpacing
          }
        },
        navText: ["<img class='carousel-nav-img img-prev' src="+ back +">","<img class='carousel-nav-img img-next' src="+ next +">"]

      });
    }, 300);
  }
}
