import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from "./base.service";
import { CookieService } from 'ngx-cookie-service';
import { CookiesResetService } from './cookies-reset.service';

@Injectable()
export class ConfigurationService extends BaseService {
  protected http: HttpClient;

    options;
    store_id;
    order_token;

  constructor( http: HttpClient, private cookieService: CookieService, public cookiesResetService: CookiesResetService) {
    super("", http);
    this.http = http;
    this.store_id = this.cookiesResetService.get('store')
    this.order_token = this.cookiesResetService.get('order_token');

    this.options = {
      headers: new HttpHeaders(
          {
              'Content-type': 'application/json',
              'X-Store-Id': this.store_id,
              'X-Spree-Order-Token': this.order_token
          }
      )
    };

  }

  resetHeader(){
    this.store_id = this.cookiesResetService.get('store')
    this.order_token = window['data']['singlePageStore']? JSON.parse(this.cookiesResetService.getSessionItem('order_token')) : this.cookiesResetService.get('order_token')
    this.options = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        'X-Store-Id': this.store_id,
        'X-Spree-Order-Token': this.order_token
      })
    };
  }

  getStores(parseFields = ""){
    return this.http.get(this.baseUrl + "stores" + parseFields)
  }

  getCurrencies(){
    return this.http.get(this.baseUrl + "general_settings/supported_currency")
  }

  getBreadcrum(taxon_id){
    return this.http.get(this.baseUrl + "taxons/"+ taxon_id)
  }

  getPermalinkBreadcrum(permaLink){
    return this.http.get(this.baseUrl + "taxons/breadcrums?permalink="+ permaLink)
  }

  askSeller(question){
    return this.http.post(this.baseUrl + "questions", question)
  }

  getQuestion(id){
    return this.http.get(this.baseUrl + "questions/" + id)
  }

  authorizeBrainTreeClient(){
    const access_token = this.cookiesResetService.get('access_token')
    const body = {
      "access_token":  access_token
    }
    this.options['params'] = body;
    return this.http.get(this.baseUrl + "authorize_braintree_client", this.options)
  }

  getStripeClientSecret(payment_method_type, stripe_payment_method_id=null){
    this.resetHeader();
    const access_token = this.cookiesResetService.get('access_token');
    const body = { "payment_method_type": payment_method_type, "stripe_pm": stripe_payment_method_id };

    return this.http.post(this.baseUrl + "stripe_client_secret?order_token=" + this.order_token + "&access_token=" + access_token, body);
  }
}
