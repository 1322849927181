import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PasswordService } from '../../services/password.service'
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import templateString from './forgot-password.component.html';
import styleString from './forgot-password.component.scss';
import {SubfoldingLinkService} from "../../services/subfolding-link.service";
import {CookiesResetService} from "../../services/cookies-reset.service";
import CustomAlert from '../../../app/models/custom-alert.model';

@Component({
  selector: 'app-forgot-password',
  template: templateString,
  styles: [ styleString ],
  providers: [ PasswordService ]
})
export class ForgotPasswordComponent implements OnInit {

  passwordForm: FormGroup;
  submitted:boolean;
  list = [{ name: 'User Dashboard', slug: '/user/account' }, { name: 'Forgot password', slug: '' }]

  constructor(
    private formBuilder: FormBuilder,
    private passwordService: PasswordService,
    private spinnerService: Ng4LoadingSpinnerService,
    private router: Router,
    public subfoldingLinkService: SubfoldingLinkService,
    public cookiesResetService: CookiesResetService
  ) {}

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
    if(!this.cookiesResetService.get('access_token')) this.list = [{ name: 'Forgot password', slug: '' }]
  }

  get f() { return this.passwordForm.controls; }

  onSubmit() {
    // stop here if form is invalid
    if (this.passwordForm.invalid) {
      this.submitted = true;
      return;
    }else{
      this.submitted = false;
      this.spinnerService.show();
       this.passwordService.findByEmail(this.passwordForm.value.email).subscribe(
         res => {
           this.spinnerService.hide();
           CustomAlert.confirm('RESET_PASSWORD_EMAIL_TEXT',{},{
            showYesActionBtn:true,
            yesActionText: 'OK',
            yesActionCallback: (self)=>{
              self.router.navigate([self.subfoldingLinkService.linkWithSubdomainOrNot('/signin-signup')]);
            },
            self: this 
          });
         },
         (error: any) => {
           this.spinnerService.hide();
           CustomAlert.show('ERROR',{ error: error.error.error });
         }
       );
    }
  }


}
