import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {BaseService} from "./base.service"
import { CookieService } from 'ngx-cookie-service';
import { User } from '../models/user.model';
import { Router } from '@angular/router';
import { RefreshService } from "./refresh.service"
import {SubfoldingLinkService} from "./subfolding-link.service"
import { CookiesResetService } from './cookies-reset.service';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import CustomAlert from '../models/custom-alert.model';

@Injectable()
export class SessionService extends BaseService {

  protected http: HttpClient;

  constructor(private window: Window, public router: Router, http: HttpClient, private cookieService: CookieService, private refreshService: RefreshService,
              public subfoldingLinkService: SubfoldingLinkService, public cookiesResetService: CookiesResetService){
    super("", http);
    this.http = http;
  }

  obtainAccessToken(loginData){
    let params = new HttpParams();
    params = params.append('username', loginData.username);
    params = params.append('password', loginData.password);
    params = params.append('user_role', "customer");
    params = params.append('grant_type','password');
    let headers = new HttpHeaders({'Content-type': 'application/x-www-form-urlencoded; charset=utf-8', 'Authorization': 'Basic '+btoa("fooClientIdPassword:secret")});
    let options = { headers: headers };
    return this.http.post('/spree_oauth/token', params.toString(), options)

  }

  isGuestUserExist(email, store_id) {
    let access_token = this.cookiesResetService.get('access_token')
    return this.http.get(this.baseUrl + "users/is_guest_exist?email=" + email + "&store_id=" + store_id)
	}

  signUp(signupData: User){
    let headers = new HttpHeaders({'Content-type': 'application/json', 'Authorization': 'Basic '+btoa("fooClientIdPassword:secret")});
    let options = { headers: headers };

    if (this.cookiesResetService.get('order_token')){
      const order_token = this.cookiesResetService.get('order_token')
      signupData["order_token"] = order_token
    }

    let hash={
      "user":signupData
    }

    return this.http.post(this.baseUrl + 'users', hash, options);
  }

  saveToken(token){
    var expireDate = new Date().getTime() + (1000 * token.expires_in);
    this.cookiesResetService.set("access_token", token.access_token, expireDate);
    this.cookiesResetService.set("roles", token.roles, expireDate);
    var recent =  JSON.stringify(token.recent_item_ids)
    this.cookiesResetService.setItem('recently_viewed', recent)
  }

  checkCredentials(){
    if (!this.cookiesResetService.check('access_token')){
      location.reload();
    }
  }

  logout() {
    const afterConfirmedLogout = (self)=>{
      self.cookiesResetService.delete('access_token',"/");
      self.cookiesResetService.delete('roles',"/");
      // self.cookiesResetService.removeItem('order_token');
      self.cookiesResetService.removeItem('recently_viewed');
      self.cookiesResetService.delete("order_token", "/");
      setTimeout(() =>
      {
        self.sendMessage();
        self.window.location.href = self.subfoldingLinkService.linkWithSubdomainOrNot("/");
      },
      1000);
    }

    CustomAlert.confirm('LOGOUT',{},{
      showNoActionBtn: true,
      showYesActionBtn:true,
      noActionText: 'CANCEL',
      yesActionText: 'LOGOUT',
      yesActionCallback: afterConfirmedLogout,
      self: this
    });

  }

  loggedIn(){
    if (this.cookiesResetService.check('access_token') && this.cookiesResetService.check('roles')){
      return true;
    }else{
      this.cookiesResetService.delete('access_token', "/");
      this.cookiesResetService.delete('roles', "/");
      return false;
    }
  }

  sendMessage(): void {
    this.refreshService.sendMessage('Message from session Component to Header Component!');
  }

  clearMessage():void{
    this.refreshService.clearMessage();
  }

  saveGuestEmail(email){
    this.cookiesResetService.set("guest_email", email, null);
  }

  deleteGuestEmail(){
    this.cookiesResetService.delete('guest_email', '/');
  }

}
