import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {BaseService} from "./base.service"
import { CookieService } from 'ngx-cookie-service';
import { CookiesResetService } from './cookies-reset.service';

@Injectable()
export class ProductService extends BaseService {
  protected http: HttpClient;
  search;
  access_token;

  options;
  store_id;
  currency;
  order_token;
  selectedStores = ['2', '62'];

  constructor(http: HttpClient, private cookieService: CookieService,
              public cookiesResetService: CookiesResetService) {
    super("products", http);
    this.http = http;

    this.currency = this.cookiesResetService.get('curency');
    this.store_id = this.cookiesResetService.get('store');
    this.access_token = this.cookiesResetService.get('access_token')

    this.options = {
      headers: new HttpHeaders(
          {
            'Content-type': 'application/json',
            'X-Store-Id': this.store_id,
            'X-Currency': this.currency
          }
      )
    };

  }

  isSelectedStore(){
    return this.selectedStores.includes(this.store_id) ? true : false
  }

  getCategory(page, perPage, taxon_id, sort, filters, priceBetween,vendor_id, permaLink, search){
    this.search = encodeURIComponent(search);
    let params:HttpParams = new HttpParams();
    let paramArray='';
    let paramsHash={};
    if (filters){
      paramsHash['property_filters']=filters
    }
    if (priceBetween){
      if(typeof(priceBetween) == "string"){
        priceBetween = priceBetween.split(',').map(function(price) {
          return parseInt(price);
        });
      }
      if (priceBetween.indexOf(this.currency) == -1)
        priceBetween.push(this.currency)
      if(priceBetween.length > 3){
        paramsHash['price_between_with_currency'] = null
      }else{
        paramsHash['price_between_with_currency'] = priceBetween
      }
    }
    if (sort){
      paramArray = paramArray + "&sort="+sort
    }
    if(this.currency != undefined){
      paramArray = paramArray + "&currency="+this.currency
    }
    if(this.store_id){
      paramsHash['stores_id_eq'] = this.store_id
    }
    if(this.search && !permaLink){
      paramsHash['name_or_brand_name_or_vendor_name_or_vendor_sku_or_meta_keywords_cont'] = this.search
    }

    if(vendor_id){
      paramsHash['name_or_brand_name_or_vendor_name_or_vendor_sku_or_meta_keywords_cont'] = "";
      paramsHash['vendor_slug_eq'] = vendor_id
    }
    paramsHash['status_in'] = "approved";
    paramsHash["taxons_permalink_eq"]=permaLink;
    params = params.set("q", JSON.stringify(paramsHash));
    this.options['params'] =params;
    var parseFields = "?&fields[product]=name,brand_name,price_values,rrp,slug,price,tax,vendor_name,images,vendor_id,vendor_landing_page,small_image,sku,vendor_microsite,vendor_slug,hide_price,hide_prod_price,option_types,variants_options,prefix,suffix,tag"
    return this.http.get(this.baseUrl + "/category_products" + parseFields + "&page="+page + "&per_page=" +perPage + paramArray, this.options)
  }

  findAllProducts(ransackParams: any) {
    let myParams = new HttpParams();
    if(JSON.stringify(ransackParams) != JSON.stringify({})){
      myParams = myParams.set("q", JSON.stringify(ransackParams))
    }
    this.options['params'] = myParams;
    return this.http.get(this.baseUrl + "?all_data=false", this.options)
  }

  recent(recent_ids){
    let addressParams={
      "product":{"ids": recent_ids}
    }
    return this.http.post(this.baseUrl + "/recent"+ "?access_token=" + this.access_token, addressParams)
  }

  show(id){
    let params:HttpParams = new HttpParams();
    let paramArray=''
    let paramsHash={}

    if(this.store_id){
      paramsHash['stores_id_eq'] = this.store_id
    }
    paramsHash['status_in'] = "approved"
    params = params.set("q", JSON.stringify(paramsHash))
    params = params.set("currency", this.currency)
    this.options['params'] = params;
    let tokenPrams = ''
    if(this.access_token){
      tokenPrams = "?access_token=" + this.access_token
    }
    return this.http.get(this.baseUrl + "/"+ id + tokenPrams, this.options)
  }

  getFilters(id, vendorId, search?){
    this.search = encodeURIComponent(search);

    let params:HttpParams = new HttpParams();
    let paramArray='';
    let paramsHash={};

    if(this.store_id){
      paramsHash['stores_id_eq'] = this.store_id
    }
    if(vendorId){
      paramsHash['vendor_slug_eq'] = vendorId
    }

    if(search && !id){
      paramsHash['name_or_brand_name_or_vendor_name_or_vendor_sku_or_meta_keywords_cont'] = this.search
    }
    paramsHash['status_in'] = "approved";
    paramsHash["taxons_permalink_eq"]=id;
    params = params.set("q", JSON.stringify(paramsHash));
    this.options['params'] =params;
    return this.http.get(this.baseUrl + "/get_filters?currency="+this.currency, this.options)
  }

  getVendor(page, perPage, vendor_id, sort, filters, priceBetween){
    this.search = this.cookiesResetService.get('name_cont')

    let params:HttpParams = new HttpParams();
    let paramArray='';
    let paramsHash={};


    if (filters){
      paramsHash['property_filters']=filters
    }
    if (priceBetween){
      if (priceBetween.indexOf(this.currency) == -1){
        priceBetween.push(this.currency)
      }
      paramsHash['price_between_with_currency'] = priceBetween
    }
    if (sort){
      paramArray = paramArray + "&sort="+sort
    }
    if(this.currency != undefined){
      paramArray = paramArray + "&currency="+this.currency
    }
    if(this.store_id){
      paramsHash['stores_id_eq'] = this.store_id
    }
    if(this.search && !vendor_id){
      paramsHash['name_or_brand_name_or_vendor_name_or_vendor_sku_or_meta_keywords_cont'] = this.search
      this.cookiesResetService.set("name_cont", "", null)
    }
    paramsHash['status_in'] = "approved"
    paramsHash["vendors_id_in"]=vendor_id
    params = params.set("q", JSON.stringify(paramsHash))
    this.options['params'] =params
    return this.http.get(this.baseUrl+"?all_data=false&page="+page + "&per_page=" +perPage + paramArray, this.options)
  }

  relatedProducts(id){
    return this.http.get(this.baseUrl + "/"+id+"/related_products", this.options)
  }

}
