import * as addressDataConfig  from '../../data/address_country_format.json';

export class AddressFormFormat {

  private addressFormats;
  public commonValidations: string[];
  public addressFields: string[];

  constructor(commonValidationsFields = ["firstName", "lastName", "country", "addressLine1", "phone"]){ 
    this.addressFields = ["firstName", "lastName", "country", "addressLine1", "addressLine2", "phone", "postCode", "city", "state", "unitNo", "region", "district", "state_id"];
    this.addressFormats = addressDataConfig['default']; 
    this.commonValidations = commonValidationsFields;
  }

  getAddressFormats(iso) { return this.addressFormats[this.resolveIso(iso)]; }
  getDefaultAddressFormats() { return this.addressFormats['Default']; }
  private resolveIso(iso) { return this.addressFormats.EU.countries.hasOwnProperty(iso)? 'EU' : this.addressFormats.hasOwnProperty(iso)? iso : 'Default'; }

}
