import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from '../services/user.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import templateString from './gift-card-balance.component.html';
import styleString from './gift-card-balance.component.scss';
import CustomAlert from '../models/custom-alert.model';

@Component({
  selector: 'app-gift-card-balance',
  template: templateString,
  styles: [styleString],
  providers: [UserService]
})
export class GiftCardBalanceComponent implements OnInit {
  storeSlug;
  balanceForm: FormGroup;
  submitted:boolean;

  constructor(
    private window: Window,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.balanceForm = this.formBuilder.group({
      giftCardNo: ['', Validators.required],
      pinNo: [''],
      robotCheck: [null, Validators.required]
    });
  }

  get f() { return this.balanceForm.controls; }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  onSubmit() {
    this.submitted = true;

    if (this.balanceForm.invalid) return; // stop here if form is invalid
    else{
      this.spinnerService.show();
      this.userService.checkGivexBalance(this.balanceForm.value.giftCardNo, this.balanceForm.value.pinNo).subscribe(res =>{
        let message;
        if(res['balance']){
          message = "Your balance is: " + res['balance'] + "<br>Currency: " + res['currency'] +  "<br>Certificate expiration date: " + res['expiry']
        }else{
          message = res
        }
        this.spinnerService.hide();
        // if(!isNaN(res)) alert(`Your balance is: ${res}`);
        // else alert(res);
        // if(typeof(res) === 'string') alert(res);
        // else alert(`Your balance is: ${res}`);
        CustomAlert.show('RESPONSE',{ response: message });
      });
    }
  }

  onVerify(event: any) {
    this.balanceForm.patchValue({ robotCheck: event });
  }

  onExpired($event: any) {
    this.balanceForm.patchValue({ robotCheck: null });
  }

  onError($event: any) {

    this.balanceForm.patchValue({ robotCheck: null });
  }
}
