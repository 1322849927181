import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Password } from '../../models/password.model'
import { PasswordService } from '../../services/password.service'
import { MustMatch } from '../../helpers/must-match.validator';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import templateString from './reset-password.component.html';
import styleString from './reset-password.component.scss';
import { SubfoldingLinkService } from '../../services/subfolding-link.service';
import {CookiesResetService} from "../../services/cookies-reset.service";
import CustomAlert from '../../../app/models/custom-alert.model';

@Component({
  selector: 'app-reset-password',
  template: templateString,
  styles: [ styleString ],
  providers: [ PasswordService ]
})
export class ResetPasswordComponent implements OnInit {

  resetPassword: FormGroup;
  submitted:boolean;
  list = [{ name: 'User Dashboard', slug: '/user/account' }, { name: 'Reset Password', slug: '' }]

  constructor(
    private formBuilder: FormBuilder,
    private passwordService: PasswordService,
     private route: ActivatedRoute,
     private router: Router,
     private spinnerService: Ng4LoadingSpinnerService,
    public subfoldingLinkService: SubfoldingLinkService,
    public cookiesResetService: CookiesResetService
  ) {}

  ngOnInit() {
    if(!this.cookiesResetService.get('access_token')) this.list = [{ name: 'Forgot password', slug: '' }]
    this.resetPassword = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirmation: ['', Validators.required],
      resetPasswordToken: ['', Validators.required],
   },{
      validator: MustMatch('password', 'passwordConfirmation')
    });

   this.route.paramMap.subscribe((params) => {
     const token = params.get('token');
     console.log(params)
     if (token) {
       this.resetPassword.patchValue({
         resetPasswordToken: token
       });
     }
   })
  }

  get f() { return this.resetPassword.controls; }

  onSubmit() {
    // stop here if form is invalid
    if (this.resetPassword.invalid) {
      this.submitted = true;
      return;
    }else{
      this.submitted = false;
      this.spinnerService.show();
       let passObj = new Password(this.resetPassword.value.resetPasswordToken, this.resetPassword.value.password, this.resetPassword.value.passwordConfirmation)
       this.passwordService.save(passObj).subscribe(res =>{
              this.spinnerService.hide();
              CustomAlert.confirm('PASSWORD_UPDATED',{},{
                showYesActionBtn:true,
                yesActionText: 'OK',
                yesActionCallback: (self)=>{
                  self.router.navigate([self.subfoldingLinkService.linkWithSubdomainOrNot('/signin-signup')]);
                },
                self: this
              });
           },
           (error: any) => {
               this.spinnerService.hide();
               CustomAlert.show('ERROR',{ error: error.error.error });
           }
      );
    }
  }

}
