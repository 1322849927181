import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import templateString from './return-init.component.html';
import styleString from './return-init.component.scss';

@Component({
  selector: 'app-return-init',
  template: templateString,
  styles: [ styleString ]
})
export class ReturnInitComponent implements OnInit {

  returnInitForm: FormGroup;
  submitted:boolean;

  constructor(private formBuilder: FormBuilder){
    this.returnInitForm = this.formBuilder.group({
      orderID: ['',Validators.required],
      clientEmail: ['',Validators.required],
    });
  }

  ngOnInit() {}

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.returnInitForm.invalid) {
      console.log("invalid")
      return;
    }
    else {
      console.log(this.returnInitForm.value);
    }
  }

}
