import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {BaseService} from "./base.service"
import { CookieService } from 'ngx-cookie-service';
import { Wishlist } from '../models/wishlist.model';
import { CookiesResetService } from './cookies-reset.service';
import {SubfoldingLinkService} from "./subfolding-link.service";

@Injectable()
export class WishlistService extends BaseService {
  protected http: HttpClient;
  currency;
  store;
  search;
  access_token;
  constructor(http: HttpClient, private cookieService: CookieService, public cookiesResetService: CookiesResetService) {
    super("wishlists", http);
    this.http = http;
    this.access_token = this.cookiesResetService.get('access_token')
  }

  delete(id) {
    return this.http.delete(this.baseUrl + '/' + id + "?access_token=" + this.access_token)
  }

  findOne(id) {
    return this.http.get(this.baseUrl + '/' + id + "?access_token=" + this.access_token)
  }

  update(id, wishlist: Wishlist) {
    let wishlistParams={
      "wishlist":wishlist
    };
    return this.http.put(this.baseUrl + '/' + id + "?access_token=" + this.access_token, wishlistParams)
  }

  save(wishlist: Wishlist) {
    let wishlistParams={
      "wishlist":wishlist
    };
    return this.http.post(this.baseUrl + "?access_token=" + this.access_token, wishlistParams)
  }

  findUserList() {
    let currency = this.cookiesResetService.get('curency');
    let options = {
      headers: new HttpHeaders({ 'X-Currency': currency })
    };

    return this.http.get(this.baseUrl + '/user_wishlist' + "?access_token=" + this.access_token, options)
  }

}
