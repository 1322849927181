// Run this Angular example by adding the following HTML markup to your view:
//
// <hello-angular>Loading...</hello-angular>
//
// <%= javascript_pack_tag 'storefront' %>
require("jquery");
require('../storefront');
require('bootstrap/dist/js/bootstrap.min.js')
require("owl.carousel");
require( "src/adyen.js");

require('owl.carousel/dist/assets/owl.carousel.min.css');
require('owl.carousel/dist/assets/owl.theme.default.min.css');
//require('bootstrap/dist/css/bootstrap.min.css');
//require("bootstrap/scss/bootstrap-grid.scss");
/*require("bootstrap/scss/_functions.scss");
require("bootstrap/scss/_variables.scss");
require("bootstrap/scss/_mixins.scss");
/*require( "../storefront/styles/_fonts.scss");
require( "../storefront/styles/_variables.scss");
require( "../storefront/styles/_mixins.scss");
require( "../storefront/styles/_layout.scss");
require( "../storefront/styles/_modal.scss");
require( "../storefront/styles/_base.scss");
require( "../storefront/styles/_utilities.scss");
require( "../storefront/styles/common-styles.scss");*/
require("font-awesome/scss/font-awesome.scss");
require( "../storefront/styles/main.scss");
