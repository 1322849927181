import {Component, Input, EventEmitter, Output} from '@angular/core';
import templateString from './buy-now-v3-guest-checkout.component.html';
import styleString from './buy-now-v3-guest-checkout.component.scss';
import { GuestCheckoutComponent } from '../../guest-checkout/guest-checkout.component'
import {SessionService} from "../../../../services/session.service";
import {SubscriptionService} from "../../../../services/subscription.service";
import {SubfoldingLinkService} from "../../../../services/subfolding-link.service";
import {FormBuilder} from "@angular/forms";

@Component({
  selector: 'app-buy-now-v3-guest-checkout',
  template: templateString,
  styles: [ styleString ],
  providers:  [ SessionService, SubscriptionService, SubfoldingLinkService ]
})
export class BuyNowGuestV3CheckoutComponent extends GuestCheckoutComponent {
  @Output() continueToShipping = new EventEmitter();
  constructor(public window: Window,
              public sessionService: SessionService,
              public formBuilder: FormBuilder,
              public subscriptionService: SubscriptionService,
              public subfoldingLinkService: SubfoldingLinkService
  ){
    super(window, sessionService, formBuilder, subscriptionService, subfoldingLinkService )
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.guestCheckout.invalid) return;
    else {
      let email = (this.guestCheckout.get('email').value).toLowerCase();
      this.sessionService.saveGuestEmail(email);
      this.continueToShipping.emit(true);
    }
  }

}
