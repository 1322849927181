import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import templateString from './no-ship-location.component.html';
import styleString from './no-ship-location.component.scss';

@Component({
  selector: 'app-no-ship-location',
  template: templateString,
  styles: [ styleString ],
})
export class NoShipLocationComponent implements OnInit {

  constructor(private dialog: MatDialog,public dialogRef: MatDialogRef<NoShipLocationComponent>) { }

  ngOnInit() {
  }

  onClose(){
    this.dialogRef.close();
  }

}
