import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { APP_BASE_HREF } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { RatingModule } from 'ng-starrating';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng5SliderModule } from 'ng5-slider';
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxStripeModule } from 'ngx-stripe';

import { CommonModule } from '@angular/common';
import { GuestWishlistComponent } from './guest-wishlist/guest-wishlist.component';
import { VendorComponent } from './vendor/vendor.component';
import { VendorBannerComponent } from './vendor/vendor-banner/vendor-banner.component';

const environment = require('../environment.json');

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CustomPipe } from './custom-pipe.module';

import { RefreshService } from './services/refresh.service';
import { SessionService } from './services/session.service';
import { SubscriptionService } from "storefront/app/services/subscription.service";
// import { SeoService } from './services/seo.service'

import { AuthGuard } from './guards/auth.guard';
import { CustomerGuard } from './guards/customer.guard';
import { GivexGuard } from './guards/givex.guard';
import { TsGuard } from './guards/ts.guard';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { CartService } from './services/cart.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { DatePickerDirective } from './datepicker.directive';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { CommonRouteIdentifierComponent } from './common-route-identifier/common-route-identifier.component';
import { ProductListingModule } from './product-listing/product-listing.module';
import { ReturnInitComponent } from './returns/return-init/return-init.component';
import { ReturnItemsComponent } from './returns/return-items/return-items.component';
import { ReturnItemsDetailsComponent } from './returns/return-items-details/return-items-details.component';
import { ReturnItemsLabelsComponent } from './returns/return-items-labels/return-items-labels.component';
import { ReturnItemSingleLabelComponent } from './returns/return-item-single-label/return-item-single-label.component';
import { CommonPageComponent } from './footer/common-page/common-page.component';
import { SubfoldingLinkService } from './services/subfolding-link.service';
import { GiftCardBalanceComponent } from './gift-card-balance/gift-card-balance.component';
import { TsGiftCardBalanceComponent } from './ts-gift-card-balance/ts-gift-card-balance.component';
import { CookiesResetService } from './services/cookies-reset.service';
import { HeaderInterceptor } from './services/header-interceptor';
import { GivexService } from './services/givex.service';
import { TsService } from './services/ts.service';
import { MatIconModule } from '@angular/material/icon';
import {NgHcaptchaModule} from "ng-hcaptcha";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    GuestWishlistComponent,
    VendorComponent,
    VendorBannerComponent,
    CommonRouteIdentifierComponent,
    ReturnInitComponent,
    ReturnItemsComponent,
    ReturnItemsDetailsComponent,
    ReturnItemsLabelsComponent,
    ReturnItemSingleLabelComponent,
    CommonPageComponent,
    GiftCardBalanceComponent,
    TsGiftCardBalanceComponent
  ],
  imports: [
    AppRoutingModule,
    SharedModule,
    CommonModule,
    ProductListingModule,
    RouterModule.forRoot([]),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    MatDialogModule,
    OverlayModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    Ng4LoadingSpinnerModule.forRoot(),
    RatingModule,
    NgxPaginationModule,
    Ng5SliderModule,
    CreditCardDirectivesModule,
    NgMultiSelectDropDownModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxStripeModule.forRoot(''),
    InfiniteScrollModule,
    CustomPipe,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgHcaptchaModule
  ],
  providers: [
    {provide: MatDialogRef,useValue: {close: () => {}}},
    {provide: APP_BASE_HREF, useValue: ''},
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    CookieService,
    { provide: Window, useValue: window },
    RefreshService,
    BrowserAnimationsModule,
    SessionService,
    // SeoService,
    AuthGuard,
    CustomerGuard,
    GivexGuard,
    TsGuard,
    CartService,
    SubfoldingLinkService,
    CookiesResetService,
    GivexService,
    TsService,
    SubscriptionService
  ],
  entryComponents: [
    CommonRouteIdentifierComponent
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
