import { Pipe, PipeTransform } from '@angular/core';
/*
 * Usage:
 *   text | truncateText: numberOfChars
 * Example:
 *   {{ 'abcdefghi' | truncateText: 3 }}
 *   output: abc...
 *   {{ 'abcdefghi' | truncateText: null }}
 *   output: abcdefghi
*/
@Pipe({name: 'truncateText'})
export class TruncateTextPipe implements PipeTransform {
  transform(text: string, numberOfChars?: number): string {
    if(!text) return text;
    if(!numberOfChars || numberOfChars >= text.length) return text;
    return text.slice(0, numberOfChars) + '...';
  }
}
