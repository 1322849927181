import { Component, OnInit, Input } from '@angular/core';
import templateString from './vendor-banner.component.html';
import styleString from './vendor-banner.component.scss';

@Component({
  selector: 'app-vendor-banner',
  template: templateString,
  styles: [ styleString ]
})
export class VendorBannerComponent implements OnInit {

  @Input() imageUrl: any;
  @Input() vendorNameparameterized = ""

  constructor() { }

  ngOnInit() {
  }

  getUrl(){
   return "url('"+this.imageUrl+"')";
  }

}
