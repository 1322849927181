import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import templateString from './buy-now-login-v3.component.html';
import styleString from './buy-now-login-v3.component.scss';
import { SubfoldingLinkService } from '../../services/subfolding-link.service';

@Component({
  selector: 'app-buy-now-login-v3',
  template: templateString,
  styles: [ styleString ],
  providers:  [SubfoldingLinkService]
})
export class BuyNowLoginV3Component implements OnInit {
  totalItemsInCart;

  constructor(public window: Window, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) data,public dialogRef: MatDialogRef<BuyNowLoginV3Component>, public subfoldingLinkService: SubfoldingLinkService){
    this.totalItemsInCart = data["totalItemsInCart"]
  }

  ngOnInit() {
    let html = document.getElementsByTagName('html')[0];
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('login-modal-open');
    html.classList.add('scroll-hidden');
  }
  
  onClose(){
    this.dialogRef.close({ continueToBuyNow: false });
    let html = document.getElementsByTagName('html')[0];
    html.classList.remove("scroll-hidden");
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-modal-open');
  }

  sendDataToDetailPage(data){
    this.dialogRef.close({ continueToBuyNow: data })
    let html = document.getElementsByTagName('html')[0];
    html.classList.remove("scroll-hidden");
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('excess-modal-open');
  }

}
