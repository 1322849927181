import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import templateString from './max-transaction-exceeded.component.html';
import { SubfoldingLinkService } from '../../services/subfolding-link.service';
import styleString from './max-transaction-exceeded.component.scss';

@Component({
  selector: 'app-max-transaction-exceeded',
  template: templateString,
  styles: [ styleString ],
})
export class MaxTransactionExceededComponent implements OnInit {

  maxCartTransaction;

  constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA, ) data, private subfoldingLinkService: SubfoldingLinkService,public dialogRef: MatDialogRef<MaxTransactionExceededComponent>) {
    this.maxCartTransaction = data;
  }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }
}
