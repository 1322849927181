import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from "./base.service"
import { CookieService } from 'ngx-cookie-service';
import { WishedProduct } from '../models/wished-product.model';
import { CookiesResetService } from '../services/cookies-reset.service';

@Injectable()
export class WishedProductService extends BaseService {
  protected http: HttpClient;
  currency;
  store;
  search;
  access_token;
  constructor(http: HttpClient, private cookieService: CookieService, public cookiesResetService: CookiesResetService) {
    super("wished_products", http);
    this.http = http;
    this.access_token = this.cookiesResetService.get('access_token')
  }

  delete(id) {
    return this.http.delete(this.baseUrl + '/' + id + "?access_token=" + this.access_token)
  }

  findOne(id) {
    return this.http.get(this.baseUrl + '/' + id + "?access_token=" + this.access_token)
  }

  update(id, wishlist: WishedProduct) {
    let wishlistParams={
      "wished_product":wishlist
    };
    return this.http.put(this.baseUrl + '/' + id + "?access_token=" + this.access_token, wishlistParams)
  }

  save(wishlist: WishedProduct) {
    let wishlistParams={
      "wished_product":wishlist
    };
    return this.http.post(this.baseUrl + "?access_token=" + this.access_token, wishlistParams)
  }

  saveForLogin(wishlist: WishedProduct, accessToken) {
    let wishlistParams={
      "wished_product":wishlist
    };
    return this.http.post(this.baseUrl + "?access_token=" + accessToken, wishlistParams)
  }

}
