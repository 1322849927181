import { Component, OnInit, Input } from '@angular/core';
import templateString from './breadcrumbs.component.html';
import styleString from './breadcrumbs.component.scss';
import { SubfoldingLinkService } from '../../services/subfolding-link.service';

@Component({
  selector: 'app-breadcrumbs',
  template: templateString,
  styles: [ styleString ],
  providers:[SubfoldingLinkService]
})
export class BreadcrumbsComponent implements OnInit {
  @Input() list;
  @Input() heading;
  storeName = window["data"].storeName;

  constructor(public subfoldingLinkService: SubfoldingLinkService) {}
  ngOnInit() {
    console.log(this.list)
  }
}
