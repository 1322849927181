import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReviewService } from '../../services/review.service';
import templateString from './reviews.component.html';
import styleString from './reviews.component.scss';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-reviews',
  template: templateString,
  styles: [ styleString ],
  providers:[ReviewService]
})

export class ReviewsComponent implements OnInit {
  @Input() sku
  @Output() reviewRating = new EventEmitter();

  reviews:any;
  reviewsData:any;
  noOfReviews = 4;
  perPage = 4;
  operatingSys;

  constructor(private window: Window,
    private activatedRoute: ActivatedRoute,
    private reviewService: ReviewService
  ){ }

  ngOnInit() {
    this.getReviews();
    this.getOS();
  }

  loadMoreReviews(){
    this.noOfReviews = this.noOfReviews + this.perPage;
    this.getReviews();
  }

  getReviews(){
    this.reviewService.getProductReviews(this.sku, this.noOfReviews, this.window["data"]["reviewsIoApiKey"], this.window["data"]["reviewsIoStoreId"]).subscribe((data) => {
      this.reviewsData = data;
      this.reviews = this.reviewsData.reviews;
      if(this.reviews.total > 0){
        var body = {
          rating: this.reviewsData.stats.average,
          reviews: this.reviewsData.stats.count
        }
        this.reviewRating.emit(body);
      }
    });
  }

  getOS() {
    var userAgent = this.window.navigator.userAgent;
    var platform = this.window.navigator.platform;
    var macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    var windows = ['Win32', 'Win64', 'Windows', 'WinCE'];
    var iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    var os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'mac';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windows.indexOf(platform) !== -1) {
      os = 'windows';
    } else if (/Android/.test(userAgent)) {
      os = 'android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'linux';
    }
    this.operatingSys = os;
    //console.log(this.operatingSys);
  }
}
