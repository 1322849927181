import { Component, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import templateString from './app.component.html';
import styleString from './app.component.scss';
import { CookiesResetService } from './services/cookies-reset.service';
import { MatDialog } from '@angular/material/dialog';
import CustomAlert from './models/custom-alert.model';

@Component({
  selector: 'app-root',
  template: templateString,
  styles: [ styleString ]
})
export class AppComponent {

  showScroll: boolean;
  showScrollHeight = 300;
  hideScrollHeight = 10;
  mailChimp= false;

  constructor(private window: Window, public router: Router, private cookieService: CookieService, @Inject(DOCUMENT) private document: Document,
              public cookiesResetService: CookiesResetService, private dialog: MatDialog)
  {
    CustomAlert.dialogObject = dialog;
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
    if(this.window["data"])
      this.mailChimp = this.window["data"]["storeItemVisibility"]["showMailChimp"]
    
  }

  @HostListener('window:scroll', [])

  ngOnInit() {
    this.onWindowScroll();
    if(this.cookiesResetService.get("access_token")) this.document.body.classList.add('auth-user');
    else this.document.body.classList.remove('auth-user');
  }

  onWindowScroll() {
    if (( this.window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > this.showScrollHeight){
      this.showScroll = true;
    }else if ( this.showScroll && (this.window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) < this.hideScrollHeight){
      this.showScroll = false;
    }
  }

  scrollToTop() {
    (function smoothscroll()
    { var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0)
      {
        window.requestAnimationFrame(smoothscroll);
        //window.scrollTo(0, currentScroll - (currentScroll / 5));
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
      }
    })();
  }
}
