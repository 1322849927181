import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import templateString from './common-route-identifier.component.html';
import styleString from './common-route-identifier.component.scss';

@Component({
  selector: 'app-common-route-identifier',
  template: templateString,
  styles: [ styleString ],
})
export class CommonRouteIdentifierComponent implements OnInit {
  routeId = '';
  categories = [];
  categoriesUrl = [];
  renderComponent = '';

  constructor(private router: Router,
    private route: ActivatedRoute,
    private window: Window) {
      if(window["data"]["categories"]){
        this.categories = window["data"]["categories"];
        if(window["data"]["categoriesUrl"]){
            this.categoriesUrl = window["data"]["categoriesUrl"];
        }
      }

      // Split the router link and get the last part
      let routeIdIdList = this.router.url.split('/');
      this.routeId = routeIdIdList[routeIdIdList.length - 1];

      // Remove the params from the link
      if(this.routeId.includes('?')) {
        let routeId = this.routeId.split('?');
        this.routeId = routeId[0];
      }

      if((this.categories.includes(this.routeId.toUpperCase()) || this.categoriesUrl.includes(this.routeId))){
          this.renderComponent = "ProductListingComponent"
      }
      else{
        this.renderComponent = "ProductDetailComponent"
      }
    }

  ngOnInit() {
  }

}
