import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class TsGiftCardService{
  protected http: HttpClient;
  hostUrl = window["data"]["tsGiftCardUrl"];

  constructor(http: HttpClient) {
    this.http = http;
  }

  getBalance(t){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic ' + btoa(window["data"]["tsGiftCardEmail"] + ':' + window["data"]["tsGiftCardPassword"])
      })
    };
    return this.http.put(this.hostUrl + "/api/v1/gift_cards/check_balance", t, httpOptions)
  }

}
