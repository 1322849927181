import { Component, OnInit } from '@angular/core';
import templateString from './footer-checkout.component.html';
import styleString from './footer-checkout.component.scss';

@Component({
  selector: 'app-footer-checkout',
  template: templateString,
  styles: [ styleString ]
})
export class FooterCheckoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
