import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from "./base.service"

@Injectable()
export class SubscriptionService extends BaseService {
  protected http: HttpClient;

  constructor(http: HttpClient) {
    super("", http);
    this.http = http;
  }

  subscribeMailchimp(email, enabledMarketing: boolean=false, newsLetter: boolean=false) {
    let body = { email: email };

    if(enabledMarketing != undefined)
      body['enabled_marketing'] = enabledMarketing;
    if(newsLetter != undefined)
      body['news_letter'] = newsLetter;

    let headers = new HttpHeaders({ 'X-Store-Id': window['data']['storeId'] });
    let options = { headers: headers };

    return this.http.post(this.baseUrl + "/users/subscribe" , body, options)
  }
}
