import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagesService } from '../../services/pages.service'
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DomSanitizer} from '@angular/platform-browser'
import { Meta, Title } from '@angular/platform-browser';
import templateString from './common-page.component.html';
import styleString from './common-page.component.html';

@Component({
  selector: 'app-common-page',
  template: templateString,
  styles: [ styleString ],
  providers:  [ PagesService ]
})
export class CommonPageComponent implements OnInit {

  pageContent;
  pageTitle;

  constructor(private pagesService: PagesService,
              private spinnerService: Ng4LoadingSpinnerService,
              private route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private meta: Meta,
              private titleService:Title
            ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      const pageId = params.get('id');
      this.getPage(pageId);
    })
  }

  getPage(pageId){
    this.spinnerService.show();
    this.pagesService.getByUrl(pageId).subscribe((data) => {
      if(data["data"]){
        this.pageContent = data["data"]["attributes"]["content"]
        this.pageTitle = data["data"]["attributes"]["title"]
      }
      this.pageContent = this.sanitizer.bypassSecurityTrustHtml(this.pageContent) ;
      this.titleService.setTitle(this.pageTitle);
      this.spinnerService.hide();
    });
  }

  getBreadcrumbs(){
    return [{ name: this.pageTitle, slug: '' }];
  }
}
