import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from "./base.service"
import { CookiesResetService } from './cookies-reset.service';

@Injectable()
export class ProductDailyStockService extends BaseService {
  protected http: HttpClient;
  store_id;
  currency;
  options;

  constructor(http: HttpClient, public cookiesResetService: CookiesResetService) {
    super("stock_products", http);
    this.http = http;
    this.currency = this.cookiesResetService.get('curency');
    this.store_id = this.cookiesResetService.get('store');

    this.options = {
      headers: new HttpHeaders(
          {
            'Content-type': 'application/json',
            'X-Store-Id': this.store_id,
            'X-Currency': this.currency
          }
      )
    };
  }

  availableProducts(productId: string, date: string) {
    const params = new HttpParams().set('parent_product_id', productId).set('date', date);
    return this.http.get(this.baseUrl + '/available_products?' + params, this.options);
  }
}