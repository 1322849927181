import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogsService } from '../../services/blogs.service'
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import templateString from './blog-post.component.html';
import styleString from './blog-post.component.scss';

@Component({
  selector: 'app-blog-post',
  template: templateString,
  styles: [ styleString ],
  providers:  [ BlogsService ]
})
export class BlogPostComponent implements OnInit {

  blog;

  constructor(private blogsService: BlogsService,
              private spinnerService: Ng4LoadingSpinnerService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      const postUrl = params.get('post-url');
      console.log(postUrl)
      this.getBlog(postUrl);
    })
  }

  getBlog(url){
    this.spinnerService.show();
    this.blogsService.getBlog(url).subscribe((data) => {
      console.log(data["data"]);
      this.blog = data["data"];
      this.spinnerService.hide();
    });
  }

  getBreadcrumbs(){
    let list = [];
    list.push({ name: 'Blog', slug: '/blog' });
    if(this.blog) list.push({ name: this.blog.blogPost.title, slug: '' });
    return list;
  }
}
