import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { CookiesResetService } from '../services/cookies-reset.service';
/*
 * Usage:
 *   array | accumulativePrice: currency
 * Example:
 *   When decimal configured as 2
 *     {{ [1, 2, 3] | accumulativePrice: '¥' }}
 *     output: 6
 *     {{ ['1', '2.3', '3'] | accumulativePrice: "$" }}
 *     output: 6.30
 *   When decimal configured as 4
 *     {{ [1, 2, 3] | accumulativePrice: '¥' }}
 *     output: 6
 *     {{ ['1', '2.3', '3'] | accumulativePrice: "$" }}
 *     output: 6.3000
 *   When decimal configured as 2 and currency formatter is off
 *     {{ [100000000, 200000000, 300000000] | accumulativePrice: '¥' }}
 *     output: 600000000
 *     {{ ['100000000', '200000000.3', '300000000'] | accumulativePrice: "$" }}
 *     output: 600000000.30
 *   When decimal configured as 2 and currency formatter is on
 *     {{ [100000000, 200000000, 300000000] | accumulativePrice: '¥' }}
 *     output: 600,000,000
 *     {{ ['100000000', '200000000.3', '300000000'] | accumulativePrice: "$" }}
 *     output: 600,000,000.30
*/

@Pipe({name: 'accumulativePrice'})
export class AccumulativePricePipe implements PipeTransform {
  decimal_points; currency;
  currency_formatter: boolean = false;
  zero_decimal_currencies =  ['BIF','CLP','DJF','GNF','JPY','KMF','KRW','MGA','PYG','RWF','UGX','VND','VUV','XAF','XOF','XPF']

  constructor(public cookiesResetService: CookiesResetService, private decimalPipe: DecimalPipe)
  {
    this.decimal_points = this.cookiesResetService.get('decimal_points');
    this.currency_formatter = JSON.parse(this.cookiesResetService.get('currency_formatter'));
    this.currency = this.cookiesResetService.get('curency');
  }

  transform(prices: Array<any>, currency=null, options: any={multiplier: 1}): string {
    if (currency == null)
      currency = this.currency;

    if (this.zero_decimal_currencies.includes(currency.toUpperCase())) {
      this.decimal_points = 0;
    } else if (options['decimalPoints']) {
      this.decimal_points = options['decimalPoints'];
    } else {
      this.decimal_points = this.cookiesResetService.get('decimal_points');
    }

    var number_pipe_string = `1.${this.decimal_points}-${this.decimal_points}`;
    var total = prices.reduce((sum, elem) => sum + (typeof elem === 'string' ? parseFloat(elem.replace(/\,/g,'')) : elem || 0), 0);
    total *= options['multiplier'];

    total = total.toFixed(this.decimal_points);
    return (this.currency_formatter ? this.decimalPipe.transform(total, number_pipe_string) : total);
  }
}
